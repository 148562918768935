import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-home-genre',
  templateUrl: './home-genre.component.html',
  styleUrls: ['./home-genre.component.scss']
})
export class HomeGenreComponent {

  genres: { id: number, name: string, content: string, image: string }[];
  constructor(
    public authService: AuthorizationService
  ) {
    this.getGenres()
  }

  getGenres() {
    this.authService.getReq('genres?per_page=10').subscribe((data: any[]) => {
      this.genres = data.map((genre: any) => {
        return {
          id: genre.id,
          name: genre.acf.name,
          content: this.authService.trimWords(genre.acf.homepage_intro, 20),
          image: genre.acf.homepage_cover_image
        }
      });
    });
  }

  slideConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    // "nextArrow": "<div class='nav-btn next-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; right: -41px; cursor: pointer'></div>",
    // "prevArrow": "<div class='nav-btn prev-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; left: -41px; cursor: pointer; transform: rotate(180deg);'></div>",
    "dots": false,
    "infinite": false,
    "autoplay": false,
    "autoplaySpeed": 2000,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 5, slidesToScroll: 1 }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }, {
        breakpoint: 600, settings: {
          slidesToShow: 2, slidesToScroll: 1
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }
    ]
  };
  slickInit(e) {
  }
  breakpoint(e) {
  }
  afterChange(e) {
  }
  beforeChange(e) {
  }
}
