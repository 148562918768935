<div class="container-fluid">
  <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'" *ngIf='images'>
    <mdb-carousel-item *ngFor='let image of images'>
      <div class="image-container w-100" [style.background-image]="'url(' + image.image + ')'">
        <div class="container">
          <h1>{{image.caption}}</h1>
        </div>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>
</div>
<div class="container text-container">
  <p class="all-center content-width text-center">
    MusiCal is an initiative by banglanatak dot com to support youths with skills and resources in developing career
    and
    business opportunities in music industry. Our focus is traditional folk and contemporary urban acoustic music.
    Globalization and technology have transformed the creative sector changing the ways of production, distribution
    and
    consumption. This change has created opportunities for diverse career pathways in creative sector. Our aim is to
    enable
    the young people unlock the potential of their creative talents and access the options that complement their
    aspirations.
  </p>
</div>
