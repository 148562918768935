import {Component} from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';
import {AlbumFinal, AlbumResp, AlbumTop, ArtistResp, GenreResp, Track, TrackTemp} from './models';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent {

  banner: { title: string, image: string, color: string };
  albums: AlbumTop[];
  albumsFinal: AlbumFinal[];
  error: boolean;
  showDetailsId: number;

  constructor(
    private authService: AuthorizationService,
    private route: ActivatedRoute
  ) {
    this.banner = {title: 'Albums', image: '../../assets/images/showcasing/MS.jpg', color: "#e64675"};
    this.route.params.subscribe((data: any) => {
      if (data.artistId) {
        this.setAlbums(parseInt(data.artistId, 10));
      } else {
        this.setAlbums();
      }
    });
    this.route.queryParams.subscribe(params => {
      if (!isNaN(params.albumId)) {
        this.showDetailsId = parseInt(params.albumId, 10);
      }
    });
  }

  setAlbums(artistId?: number): void {
    this.getJson('albums')
      .then((data: AlbumResp[]) => {
        if (artistId) {
          this.albums = data.map((album: AlbumResp) => {
            if (album.acf.artists && (album.acf.artists.indexOf(artistId) > -1)) {
              const item = {...album.acf, id: album.id};
              delete item.status;
              delete item.artists;
              return item;
            }
          }).filter(album => album);
        } else {
          this.albums = data.map((album: AlbumResp) => {
            const item = {...album.acf, id: album.id};
            delete item.status;
            delete item.artists;
            return item;
          });
        }
        this.getAlbumFinal();
      })
      .catch(() => {
        this.error = true;
      });
  }

  getAlbumFinal(): void {
    Promise.all([this.getJson('genres'), this.getJson('artists')])
      .then(results => {
        this.processAlbumFinal(results[0] as GenreResp[], results[1] as ArtistResp[]);
      }).catch(err => {
      console.log(err);
      this.error = true;
    });
  }

  processAlbumFinal(genreResp?: GenreResp[], artistResp?: ArtistResp[]): void {
    const albumsFinal: AlbumFinal[] = [];
    this.albums.forEach(album => {
      const albumGenre = genreResp?.find(genre => genre.id === album.genre)?.acf.name;
      const item: AlbumFinal = {
        ...album,
        tracks: album.tracks && this.getTracksFromResp(album.tracks, genreResp, artistResp),
        genre: albumGenre
      };
      albumsFinal.push(item);
    });
    this.albumsFinal = albumsFinal;
  }

  getTracksFromResp(trackResp: TrackTemp[], genreResp?: GenreResp[], artistResp?: ArtistResp[]): Track[] {
    const tracks: Track[] = [];
    trackResp.forEach(item => {
      const artists = item.artists && item.artists.map(artistId =>
        artistResp?.find(artist => artist.id === artistId)?.acf.name)
        .filter(artist => artist);

      tracks.push({
        title: item.title,
        artists,
        genre: genreResp?.find(genre => genre.id === item.genre)?.acf.name
      });
    });
    return tracks;
  }

  getJson(url: string): Promise<object> {
    return new Promise((resolve, reject) => {
      this.authService.getReq(`${url}?per_page=1000`)
        .subscribe((data) => resolve(data as any),
          error => {
            console.log(`Failed to fetch ${url}.`, error);
            reject(error);
          });
    });
  }
}


