<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" id="myModalLabel">{{book.title}}</h4>
</div>
<div class="d-flex flex-column modal-body">
  <img class="mx-auto" [src]="book.image">
  <div class="px-3 my-4">
    <h2 class="text-center">{{book.title}}</h2>
    <p *ngIf="book.author" class="text-center">By {{book.author}}</p>
    <p class="text-center">{{book.about}}</p>
  </div>
</div>
