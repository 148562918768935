import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthorizationService} from 'src/app/services/authorization.service';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistsComponent implements OnInit {

  banner: { image: string, title: string, color: string };
  artists: { id: number, name: string, intro: string, cover: string, images: string[], videos: string[] }[];
  p = 1;
  genreId: number;
  featuredIDs: number[];

  constructor(
    private route: ActivatedRoute,
    private authService: AuthorizationService
  ) {
    route.params.subscribe((data: any) => {
      // this.getArtists(data.genreId);
      this.genreId = data.genreId;
    });
  }

  async ngOnInit(): Promise<void> {
      await this.getGenreDetails();
      await this.getAritstDetails();
  }

  async getGenreDetails(): Promise<void> {
    const genre = await this.authService.getReq<any>(`genres/${this.genreId}`).toPromise();
    if (genre) {
      this.banner = {
        image: genre.acf.page_banner,
        title: genre.acf.name,
        color: "#e64675"
      }
      this.featuredIDs = genre.acf.featured_artists;
    }
  }

  async getAritstDetails(): Promise<void> {
    const allArtists = await this.authService.getReq<any[]>("artists?per_page=1000").toPromise();
    if (allArtists?.length) {
      this.artists = allArtists
        .filter(a => this.featuredIDs?.includes(a?.id) && a?.acf?.featured)
        .map(a => {
          return {
            id: a.id,
            name: a.acf.name,
            intro: a.acf.content,
            cover: a.acf.image,
            images: a.acf.gallery ? a.acf.gallery.map((image: any) => image.image) : false,
            videos: a.acf.videos ? a.acf.videos.map((video: any) => video.youtube_link) : false
          }
        });
    } else this.artists = [];
  }


  handlePage(input: number) {
    this.p = input;
    window.scrollTo({top: 300});
  }
}
