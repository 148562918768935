import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-styled-banner',
  templateUrl: './styled-banner.component.html',
  styleUrls: ['./styled-banner.component.scss']
})
export class StyledBannerComponent {

  @Input() banner: { image: string, title: string, color: string };
  constructor() { }

}
