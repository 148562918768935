import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-music-space',
  templateUrl: './music-space.component.html',
  styleUrls: ['./music-space.component.scss']
})
export class MusicSpaceComponent {

  banner: { title: string, image: string, color: string };
  spaces: { id: number, title: string, coverPicture: string, intro: string }[];
  constructor(
    public authService: AuthorizationService
  ) {
    this.getSpaces();
    this.banner = { title: 'Music Space', image: '../../../../assets/images/showcasing/MS.jpg', color: "#e64675" }
  }

  getSpaces() {
    this.authService.getReq('https://cms.bncmusical.co.in/wp-json/wp/v2/music_space_taxonomies', true).subscribe((data: any[]) => {
      this.spaces = data.map((space: any) => {
        return {
          id: space.id,
          title: space.acf.title,
          coverPicture: space.acf.cover_picture,
          intro: space.acf.introduction
        }
      });
    })
  }
}
