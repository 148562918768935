import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-other-events',
  templateUrl: './other-events.component.html',
  styleUrls: ['./other-events.component.scss']
})
export class OtherEventsComponent {


  banner: { image: string, title: string, color: string };
  events: { title: string, date: string, endDate: string, images: string[], content: string }[];
  constructor(
    private authService: AuthorizationService
  ) {
    this.banner = { image: '../../../../assets/images/showcasing/OE.jpg', title: 'Other Events', color: "#e64675" };
    this.getEvents();
  }

  getEvents() {
    this.authService.getReq('other_events?per_page=100').subscribe((data: any[]) => {
      this.events = data.map((event: any) => {
        return {
          title: event.acf.title,
          date: event.acf.date,
          endDate: event.acf.end_date,
          content: event.acf.content,
          images: event.acf.images.map((image: any) => {
            return image.image
          })
        }
      });
    })
  }

}
