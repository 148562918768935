<div class="container my-4 p-2">
  <div class="row">
    <div class="col-md-4 p-2">
      <div class="cover-holder" [style.background-image]="'url('+gallery.cover+')'">
        <div class="mask rgba-black-strong p-5">
          <h4 class="white-text mt-1">{{gallery.title}}</h4>
          <p class="white-text mt-2" style="font-style: italic;">{{gallery.date}}</p>
          <p class="white-text mt-2">{{gallery.intro}}</p>
        </div>
      </div>
    </div>
    <div class="col-md-8 p-0">
      <div class="row" *ngIf='gallery.medias'>
        <div class="col-md-4 p-2" *ngFor='let media of gallery.medias | slice : 0:6; let i = index; '>
          <div class="media-holder" (click)='openModal(gallery.medias, i)'
            [style.background-image]="media.type === 'Image' ? 'url('+media.media+')' : 'url(' + youtubeMedia + ')'">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-image-modal *ngIf="modalIndex !== null && modalIndex !== undefined" [images]="modalImages" [index]="modalIndex"
  (close)="closeModal()"></app-image-modal>