import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent {

  banner: { image: string, title: string };
  directories: directory[];
  filteredDirectories: (false | directory)[];
  genres: string[] = ['All'];
  selectedGenre: string = 'All';
  villages: string[] = ['All'];
  selectedVillage = 'All';
  districts: string[] = ['All'];
  selectedDistrict: string = 'All';
  states:string[] = ['All'];
  selectedState = 'All';
  headers = [' ', 'Name', 'Genre', 'Village', 'State', 'District', 'Phone'];
  currentPage = 1;

  constructor(
    private authService: AuthorizationService
  ) {
    this.getDirectories();
  }



  getDirectories() {
    this.authService.getReq('directories?per_page=300').subscribe((data: any[]) => {
      this.directories = data.map((directory: any) => {
        if (directory.acf.genre && this.genres.indexOf(directory.acf.genre) === -1) {
          this.genres.push(directory.acf.genre);
        }
        if (directory.acf.village && this.villages.indexOf(directory.acf.village) === -1) {
          this.villages.push(directory.acf.village);
        }
        if (directory.acf.district && this.districts.indexOf(directory.acf.district) === -1) {
          this.districts.push(directory.acf.district);
        }
        if (directory.acf.state && this.states.indexOf(directory.acf.state) === -1) {
          this.states.push(directory.acf.state);
        }
        if (directory.acf.name) 
        {
          // if (!directory.acf.state) directory.acf.state = "";
            return {
            name: directory.acf.name,
            image: directory.acf.image,
            district: directory.acf.district,
            village: directory.acf.village,
            genre: directory.acf.genre,
            phone: directory.acf.phone_number,
            state: directory.acf.state
          }
        }
      }).filter(directory => directory).sort((a, b) => ((a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : 1));
      this.filteredDirectories = this.directories;
    });
  }

  handleSelect(type: string, input: string) {
    console.log('clicked on select', input);
    if (type === 'Genre') {
      this.selectedGenre = input;
    } else if (type === 'District') {
      this.selectedDistrict = input;
    } else if (type === 'Village') {
      this.selectedVillage = input;
    } else if (type === 'State') {
      this.selectedState = input;
    }
    console.log(this.selectedVillage, this.selectedGenre, this.selectedDistrict, this.selectedState);
    this.filteredDirectories = this.directories.map((directory: directory) => {
      if (this.selectedState === 'All') {
        return directory;
      } else {
        if (this.selectedState === directory.state) {
          return directory;
        } else {
          return false;
        }
      }
    }).map((directory: directory) => {
      if (this.selectedGenre === 'All') {
        return directory;
      } else {
        if (this.selectedGenre === directory.genre) {
          return directory;
        } else {
          return false;
        }
      }
    }).map((directory: directory) => {
      if (this.selectedDistrict === 'All') {
        return directory;
      } else {
        if (this.selectedDistrict === directory.district) {
          return directory;
        } else {
          return false;
        }
      }
    }).map((directory: directory) => {
      if (this.selectedVillage === 'All') {
        return directory;
      } else {
        if (this.selectedVillage === directory.village) {
          return directory;
        } else {
          return false;
        }
      }
    }).filter(directory => directory);
  }

  handlePage(input: number) {
    this.currentPage = input;
    window.scrollTo({ top: 300 });
  }

}

interface directory {
  name: string;
  image: string;
  genre: string;
  district: string;
  village: string;
  phone: string;
  state: string;
}