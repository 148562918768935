import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-holder',
  templateUrl: './residency-image-holder.component.html',
  styleUrls: ['./residency-image-holder.component.scss']
})
export class ResidencyImageHolderComponent implements OnInit {

  @Input() residency: { title: string, video: string, cover: string, content: string, images: string[] };
  @Output() openModal: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  startModal(index) {
    this.openModal.emit(index);
  }

  /**
   * Returns a thumbnail URL for the given video
   * @param vidUrl Expected format: "https://www.youtube.com/embed/<id>" or "https://www.youtube.com/watch?v=<id>"
   */
  getVidThumbnailUrl(vidUrl: string): string {
    const embedUrl = vidUrl.replace('watch?v=', 'embed/');
    const id = embedUrl.substr(embedUrl.lastIndexOf('/'));
    return `https://img.youtube.com/vi${id}/mqdefault.jpg`;
  }

}
