import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-home-upper',
  templateUrl: './home-upper.component.html',
  styleUrls: ['./home-upper.component.scss']
})
export class HomeUpperComponent {

  images: { caption: string, image: string }[];
  constructor(
    private authService: AuthorizationService
  ) {
    authService.getReq('home_banner_images').subscribe((data: any[]) => {
      this.images = data.map((image: any) => {
        return {
          caption: image.acf.caption ? image.acf.caption : false,
          image: image.acf.image ? image.acf.image : false
        }
      });
    })
  }

}
