import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-residencies',
  templateUrl: './residencies.component.html',
  styleUrls: ['./residencies.component.scss']
})
export class ResidenciesComponent {

  residencies: { title: string, video: string, cover: string, content: string, images: string[] }[];
  modalImages: string[];
  modalIndex: number;
  currentPage: number;
  isSingleResidency: boolean;

  constructor(
    public authService: AuthorizationService,
    private route: ActivatedRoute
  ) {
    route.params.subscribe((data: any) => {
      if (data && data.residencyId) {
        this.getSingleResidency(data.residencyId);
      } else {
        this.getResidencies();
      }
    })

  }

  getResidencies() {
    this.isSingleResidency = false;
    this.authService.getReq('residencies?per_page=300').subscribe((data: any[]) => {
      this.residencies = data.map((residency: any) => {
        if (residency.acf.status) {
          return {
            title: residency.acf.title,
            video: residency.acf.youtube,
            content: residency.acf.content,
            cover: residency.acf.cover_picture,
            images: [
              residency.acf.gallery[0] ? residency.acf.gallery[0].image : false,
              residency.acf.gallery[1] ? residency.acf.gallery[1].image : false,
              residency.acf.gallery[2] ? residency.acf.gallery[2].image : false,
              residency.acf.gallery[3] ? residency.acf.gallery[3].image : false,
              residency.acf.gallery[4] ? residency.acf.gallery[4].image : false,
            ]
          }
        }
      }).filter(residency => residency).reverse();
      this.currentPage = 1;
    });
  }

  getSingleResidency(id: string) {
    this.isSingleResidency = true;
    this.authService.getReq('residencies/' + id).subscribe((residency: any) => {
      this.residencies = [{
        title: residency.acf.title,
        video: residency.acf.youtube,
        content: residency.acf.content,
        cover: residency.acf.cover_picture,
        images: [
          residency.acf.gallery[0] ? residency.acf.gallery[0].image : false,
          residency.acf.gallery[1] ? residency.acf.gallery[1].image : false,
          residency.acf.gallery[2] ? residency.acf.gallery[2].image : false,
          residency.acf.gallery[3] ? residency.acf.gallery[3].image : false,
          residency.acf.gallery[4] ? residency.acf.gallery[4].image : false,
        ]
      }]
    })
  }

  openModal(residency, index: number) {
    const images = residency.images.slice();
    if (residency.video) {
      images.splice(1, 0, residency.video);
    }
    this.modalImages = images;
    this.modalIndex = index;
  }

  closeModal() {
    this.modalIndex = null;
    this.modalImages = null;
  }
}
