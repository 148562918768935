<app-grey-seperator *ngIf="events?.length"></app-grey-seperator>
<div class="container" *ngIf="events?.length">
  <br>
  <h2>Upcoming Events</h2>
  <br>
  <ngx-slick-carousel *ngIf='events && events.length' class="carousel" #slickModal="slick-carousel"
    [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let event of events" class="slide">
      <div class="card">
        <div class="image-container" [ngStyle]='{"background-image": "url(" + event?.image + ")"}'></div>
        <div class="content p-2 bg-grey">
          <p>{{event?.name}}</p>
        </div>
        <div class="bg-grey">
          <!-- <h4 class="bg-orange">View More</h4> -->
        </div>
      </div>
    </div>

  </ngx-slick-carousel>

  <br>
</div>