<div class="container-fluid">
  <br><br>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="m-auto" style="width: fit-content;">
          <h4>Quick Links</h4>
          <p (click)="authService.navigateToPage('')">Home</p>
          <p (click)="authService.navigateToPage('music-festivals')">Music Festival</p>
          <p (click)="authService.navigateToPage('albums')">Album</p>
          <p (click)="authService.navigateToPage('books')">Book</p>
          <p (click)="authService.navigateToPage('documentaries')">Documentary</p>
          <p (click)="authService.navigateToPage('directory')">Directory</p>
          <p>
            <a href="https://play.google.com/store/apps/details?id=folks.of.bengal.bnc.app" target="blank">
            Download App</a>
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="m-auto" style="width: fit-content;">
          <h4>Featured Website</h4>
          <a href="https://banglanatak.com/" target="_blank">
            <p>www.banglanatak.com</p>
          </a>
          <a href="http://surjahan.com/" target="_blank">
            <p>www.surjahan.com</p>
          </a>
          <a href="http://toureast.in/" target="_blank">
            <p>www.toureast.com</p>
          </a>
          <a href="https://baulfakiri.com" target="_blank">
            <p>www.baulfakiri.com</p>
          </a>
          <a href="https://rajasthansafar.com" target="_blank">
            <p>www.rajasthansafar.com</p>
          </a>
        </div>
      </div>
      <div class="col-md-3 text-right">
        <h4>Contact Us</h4>
        <p>188/89 Prince Anwar Shah Road,</p>
        <p>Kolkata-700045</p>
        <p>email:musical@bangalantak.com</p>
        <p>+91-33-40047484</p>
      </div>
    </div>
    <br>
    <div class="text-center">
      <img width="80" src="../../../../assets/images/common/cc by nc.png" alt="">
      <p>All content is made available under the CC-BY-NC license</p>
    </div>
  </div>
  <br>
  <div class="p-2 text-center developed">
    <p>Designed & Developed by banglanatak dot com</p>
  </div>
  <br>
</div>
