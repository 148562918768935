import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-music-space-facebook',
  templateUrl: './music-space-facebook.component.html',
  styleUrls: ['./music-space-facebook.component.scss']
})
export class MusicSpaceFacebookComponent {

  constructor(
    public authService: AuthorizationService
  ) { }

}
