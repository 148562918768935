<div class="root" (click)="close.emit(null)">
  <button type="button" class="close" aria-label="Close" (click)="close.emit(null)">
    <span aria-hidden="true">×</span>
  </button>

  <span class="index" (click)="$event.stopPropagation()">{{index + 1}}/{{images.length}}</span>

  <div class="image" *ngIf="images && index !== undefined && index !== null" (click)="$event.stopPropagation()">
    <img *ngIf="images[index] && !isVideo(images[index])" [src]="images[index]">

    <div class="embed-responsive embed-responsive-16by9" *ngIf="images[index] && isVideo(images[index])">
      <iframe class="embed-responsive-item" [src]="getVid(images[index])" allowfullscreen></iframe>
    </div>

    <p *ngIf="!images[index]">No image available</p>
  </div>
  <h2 *ngIf="images && index !== undefined && index !== null && getTitle(images[index])"
      class="title" (click)="$event.stopPropagation()">{{getTitle(images[index])}}</h2>

  <a class="next" (click)="nextImage(); $event.stopPropagation()"></a>
  <a class="prev" (click)="prevImage(); $event.stopPropagation()"></a>

</div>
