<app-styled-banner [banner]="{'image':'../../../../assets/images/books/books-banner.jpg', 'title': 'Books', color: '#e64675'}">
</app-styled-banner>
<div class="container">
  <h2 class="row my-5">Books Published</h2>

  <div class="row" *ngIf="books">
    <div class="col-lg-3 col-md-4 col-sm-6 pl-0 pr-4 pb-4 book"
      *ngFor="let book of books | paginate: { itemsPerPage: 10, currentPage: currentPage }" (click)="showModal(book)">
      <div class="bg-grey book-holder">
        <div class="book-image-holder" [style.background-image]="'url(' + book.image + ')'"></div>
        <!-- <img [src]="book.image" class="embed-responsive embed-responsive-1by1" alt="Cover"> -->
        <div class="mt-4 mb-4 px-2">
          <h4 class="text-center">{{book.title}}</h4>
          <p class="text-center">{{book.about}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="text-center">
  <pagination-controls (pageChange)="currentPage = $event; scrollToTop()" autoHide="true">
  </pagination-controls>
</div>