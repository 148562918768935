import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-genre',
  templateUrl: './genre.component.html',
  styleUrls: ['./genre.component.scss']
})
export class GenreComponent {

  genre: { id: number, name: string, intro: string, introImage: string, introImageWidth: string, bannerImage: string, contents: { title: string, content: string, isImage: boolean, imagePosition: string, imageWidth: string, image: string }[] };
  sections: { width: string, image: string, title: string, content: string }[];
  sectionsPosition: number;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthorizationService
  ) {
    route.params.subscribe((data: any) => {
      this.getGenre(data.id);
    })
  }

  getGenre(input: number) {
    this.authService.getReq('genres/' + input).subscribe((data: any) => {
      this.genre = {
        id: input,
        name: data.acf.name,
        intro: data.acf.page_intro,
        introImage: data.acf.page_intro_image,
        introImageWidth: data.acf.page_intro_image_width,
        bannerImage: data.acf.page_banner,
        contents: data.acf.content.map((content: any) => {
          return {
            title: content.title,
            content: content.content,
            isImage: content.has_image,
            imagePosition: content.image_position,
            imageWidth: content.image_width,
            image: content.image,
            sections: content.has_sub_sections
          }
        })
      }
      if (data.acf.sections_content) {
        this.sectionsPosition = parseInt(data.acf.sections_position);
        this.sections = data.acf.sections_content.map((section: any) => {
          return {
            width: section.width,
            image: section.image,
            title: section.title,
            content: section.content
          }
        })
      } else {
        this.sectionsPosition = null;
        this.sections = [];
      }
    })
  }

}
