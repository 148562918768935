<div class="container my-5">
  <div class="row">
    <div *ngFor="let live of lives | paginate: { itemsPerPage: 6, currentPage: currentPage }" class="col-md-6 my-3">
      <div class="fb-holder">
        <div class="fb-iframe" [innerHTML]="live.embed_content"></div>
        <p class="text-center mx-auto">{{live.title}}</p>
      </div>
    </div>
  </div>
  <div class="my-4 text-center">
    <br>
    <pagination-controls (pageChange)="handlePaginationController($event)" autoHide="true">
    </pagination-controls>
    <br>
  </div>
</div>