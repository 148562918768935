import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-image-holder-generic',
  templateUrl: './image-holder-generic.component.html',
  styleUrls: ['./image-holder-generic.component.scss']
})
export class ImageHolderGenericComponent implements OnInit {

  @Input() title: string;

  /**
   * Image to display. Has to be provided, except when vidLink is provided.
   */
  @Input() imgLink: string;

  /**
   * List of videos to display. Should be provided only when vidLinks is not provided.
   */
  @Input() vidLink: string;

  @Output() click = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    if (this.vidLink) {
      this.imgLink = this.getVidThumbnailUrl(this.vidLink);
    }
  }

  /**
   * Returns a thumbnail URL for the given video
   * @param vidUrl Expected format: "https://www.youtube.com/embed/<id>"
   */
  getVidThumbnailUrl(vidUrl: string): string {
    let id = vidUrl.substr(vidUrl.lastIndexOf('/'));
    return `https://img.youtube.com/vi${id}/mqdefault.jpg`
  }
}
