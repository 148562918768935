import { Component, Input } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-artist-holder',
  templateUrl: './artist-holder.component.html',
  styleUrls: ['./artist-holder.component.scss']
})
export class ArtistHolderComponent {

  @Input() artist: { id: number, name: string, intro: string, cover: string, images: string[], videos: string[] };
  modalImages: string[];
  modalIndex: number;

  constructor(
    public authService: AuthorizationService
  ) { }

  openModal(gallery, index: number) {
    this.modalImages = gallery;
    this.modalIndex = index;
  }

  closeModal() {
    this.modalIndex = null;
    this.modalImages = null;
  }
}
