import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MDBModalRef} from 'angular-bootstrap-md';

@Component({
  selector: 'app-books-modal',
  templateUrl: './books-modal.component.html',
  styleUrls: ['./books-modal.component.scss']
})
export class BooksModalComponent implements OnInit {

  book: { id: number, title: string, about: string, author: string, image: string };

  constructor(public modalRef: MDBModalRef) {
  }

  ngOnInit(): void {
  }

}
