import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'
import {Params, Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {


  base_url = 'https://cms.bncmusical.co.in/wp-json/acf/v3';
  mail_url = 'https://mailer.bncmusical.co.in/sendmail';

  constructor(
    private http: HttpClient,
    public sanatizer: DomSanitizer,
    private router: Router
  ) {
  }

  getReq<T>(relativeUrl: string, nonbase?: boolean, params?:HttpParams): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (params) {
      httpOptions['params'] = params;
    }
    if (nonbase && nonbase === true) {
      return this.http.get(encodeURI(relativeUrl), httpOptions) as unknown as Observable<T>;
    } else {
      return this.http.get(encodeURI(this.base_url + '/' + relativeUrl), httpOptions) as unknown as Observable<T>;
    }
  }

  sanatizeUrl(url: string) {
    let returnUrl: SafeResourceUrl;
    if (url.length) {
      returnUrl = this.sanatizer.bypassSecurityTrustResourceUrl(url);
      return returnUrl;
    }
  }

  sanatizeVideoUrl(url: string) {
    let returnUrl: SafeResourceUrl;
    if (url.length) {
      returnUrl = this.sanatizer.bypassSecurityTrustUrl(url);
      return returnUrl;
    }

  }

  sanatizeHtml(url: string) {
    let returnUrl: SafeResourceUrl;
    if (url.length) {
      returnUrl = this.sanatizer.bypassSecurityTrustHtml(url);
      return returnUrl;
    }
  }

  navigateToPage(link: string, params?: Params) {
    let extras = params ? {queryParams: params} : undefined;
    this.router.navigate([link], extras);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 500);
  }


  sendMail(body) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.mail_url, body, httpOptions);
  }

  trimWords(input: string, trimIndex: number) {
    let words = input.split(' ');
    words = words.slice(0, trimIndex);
    return (words.join(' ') + '..');
  }

}
