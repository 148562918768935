<div class="container">
  <h2 *ngIf='!isSingleResidency'>Residency Videos and Pictures</h2>
  <div *ngIf='isSingleResidency && residencies'>
    <br><br>
    <mdb-breadcrumb>
      <mdb-breadcrumb-item class="blue-text" (click)='authService.navigateToPage("residency")'>
        <h4>All Residencies</h4>
      </mdb-breadcrumb-item>
      <mdb-breadcrumb-item class="active">
        <h4>{{residencies[0].title}}</h4>
      </mdb-breadcrumb-item>
    </mdb-breadcrumb>
  </div>
  <app-image-holder *ngFor="let residency of residencies | paginate: { itemsPerPage: 4, currentPage: currentPage }"
    [residency]="residency" (openModal)="openModal(residency, $event)"></app-image-holder>
</div>
<div class="text-center">
  <pagination-controls *ngIf="currentPage" (pageChange)="currentPage = $event" autoHide="true">
  </pagination-controls>
  <br>
</div>

<app-image-modal *ngIf="modalIndex !== null && modalIndex !== undefined" [images]="modalImages" [index]="modalIndex"
  (close)="closeModal()"></app-image-modal>