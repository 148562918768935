<app-styled-banner *ngIf='space' [banner]='{image: space.banner, title: space.title, color: "#e64675"}'></app-styled-banner>
<br>
<div class="container text-center">
  <div class="content-width m-auto" *ngIf='space'>
    <h2>{{space.title}}</h2>
    <p>{{space.intro}}</p>
  </div>
</div>

<br>

<div *ngFor='let gallery of gallerys | paginate: { itemsPerPage: 5, currentPage: p }' class="py-2">
  <app-space-gallery-holder [gallery]='gallery'></app-space-gallery-holder>
</div>
<div class="text-center">
  <br>
  <pagination-controls (pageChange)="p = $event; handlePageChange();"></pagination-controls>
  <br>
</div>