<div class="container">
  <h2>Music Residency</h2>
</div>
<div class="container-fluid">
  <img class="w-100" src="../../../../assets/images/home/1.jpg" alt="">
</div>
<div class="container p-4">
  <p class="content-width text-center">We host 10-14 days' residencies preferably with duos, trios, quartets. We
    organize
    performances for the guest artists in Kolkata, at 1-2 village festival(s), organize collaborations with both urban
    youth and rural traditional folk musicians. Sometimes the residencies are followed by recording and publication of
    collaborative works, depending on the nature of the collaboration. We encourage artists interested in such exchanges
    and collaborations, and seeking to experience Indian folk music traditions, to write to us. We are open to
    discussions to further customize the residencies on need-basis, while preserving the basic concept and spirit Of the
    residency. We have also started work with desert musicians for last few months and plan to host a few residencies
    there too.
  </p>
  <br>
  <h4 class="bg-orange px-4 px-2" (click)='authService.navigateToPage("residency")'>View More</h4>
</div>
<div class="container">
  <br>
  <ngx-slick-carousel *ngIf='residencies && residencies.length' class="carousel" #slickModal="slick-carousel"
    [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let residency of residencies" class="slide">
      <div class="row residency">
        <div class="col-md-4">
          <img class="w-100 bg-grey p-2" src="{{residency.picture}}" alt="">
        </div>
        <div class="col-md-8 p-0">
          <div class="bg-grey h-100 p-4">
            <h4>{{residency.name}}</h4>
            <p class="text-italic">{{residency.date}}</p>
            <br>
            <p>{{residency.content}}</p>

            <h4 class="bg-orange view-more p-2 mb-0" (click)='authService.navigateToPage("residency/" + residency.id)'>
              View More</h4>
          </div>
        </div>
      </div>
    </div>

  </ngx-slick-carousel>

  <br>
</div>