<header class="container-fluid mx-auto">

  <div class="row">
    <h2 class="my-5 mx-auto">{{festival.title}}</h2>
  </div>

  <div class="row">
    <img [src]="festival.coverPicture" class="col-md-4 col-sm-7 p-0 mx-auto">
    <div class="embed-responsive embed-responsive-21by9 col-md-8" *ngIf="coverYT">
      <iframe class="embed-responsive-item" [src]="coverYT" allowfullscreen></iframe>
    </div>
  </div>

  <div class="row">

    <div class="mt-5 mb-4 mx-auto col-xl-7 col-lg-9">
      <h4 class="text-center">About the festival</h4>
      <p class="my-3 text-center">{{festival.about}}</p>

      <div class="d-flex">
        <mdb-icon fas size="2x" icon="caret-left amber-text" class="mr-auto img-fest" (click)="prevFest.emit(null)">
        </mdb-icon>

        <mdb-icon fas size="2x" icon="caret-right amber-text" class="img-fest" (click)="nextFest.emit(null)"></mdb-icon>
      </div>

      <a class="text-center" href="http://toureast.in" target="_blank">
        <h4 class="my-0">
          <span class="py-1 px-4 link-block">
            toureast.in
          </span>
        </h4>
      </a>
    </div>

  </div>

</header>

<div class="container mb-5">

  <div class="row">
    <app-grey-seperator class="col px-0" [caption]="'stories'"></app-grey-seperator>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6 col-12 mb-3" *ngFor="let story of festival.stories">
      <app-image-holder-generic [title]="story.title" [imgLink]="story.image" (click)="openUrl(story.link)">
      </app-image-holder-generic>
    </div>
  </div>

  <div class="row mt-2">
    <app-grey-seperator class="col px-0" [caption]="'multimedia'"></app-grey-seperator>
  </div>

  <div class="row">
    <div class="col-lg-4 col-md-6 col-12 mb-3">
      <app-image-holder-generic [title]="'Photo Archives'" [imgLink]="festival.photoArchives[0]"
        (click)="showModal(festival.photoArchives)"></app-image-holder-generic>
    </div>

    <div class="col-lg-4 col-md-6 col-12 mb-3">
      <app-image-holder-generic [title]="'Video Archives'" [vidLink]="festival.videoArchives[0]"
        (click)="showModal(festival.videoArchives)"></app-image-holder-generic>
    </div>

    <div class="col-lg-4 col-md-6 col-12 mb-3">
      <app-image-holder-generic [title]="'Print Media Archives'"
        [imgLink]="getPicsFromNested(festival.printMediaArchives)[0]"
        (click)="showModal(getPicsFromNested(festival.printMediaArchives))">
      </app-image-holder-generic>
    </div>
  </div>

  <div class="row mt-2" *ngIf="festival.partners && festival.partners[0]">
    <app-grey-seperator class="col px-0" [caption]="'partners'"></app-grey-seperator>
  </div>

  <div class="row" *ngIf="festival.partners && festival.partners[0]">

    <ngx-slick-carousel class="carousel col" #slickModal="slick-carousel" [config]="slideConfig"
      (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
      (beforeChange)="beforeChange($event)">

      <div ngxSlickItem *ngFor="let partner of festival.partners" class="slide mx-1">
        <!-- <app-image-holder-generic [title]="partner.title" [imgLink]="partner.image"></app-image-holder-generic> -->

        <img class="p-4 w-100 all-center" src="{{partner.image}}" alt="">

      </div>

    </ngx-slick-carousel>
  </div>

</div>

<app-image-modal *ngIf="modalUrls" [images]="modalUrls" [index]="0" (close)="closeModal()"></app-image-modal>