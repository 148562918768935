<div class="container">
  <h2>Genre</h2>
  <br>
  <ngx-slick-carousel *ngIf='genres && genres.length' class="carousel" #slickModal="slick-carousel"
    [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
    (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let genre of genres" class="slide">
      <div class="genre">
        <div class="image" [ngStyle]="{ 'background-image': 'url(' + genre.image + ')'}">
          <h2 class="name">{{genre.name}}</h2>
        </div>
        <div class="p-2 bg-grey">
          <p class="content">{{genre.content}}</p>
          <p class="my-2 orange-text" (click)='authService.navigateToPage("genre/"+genre.id)'>Read More ..</p>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>