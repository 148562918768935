import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-music-festival',
  templateUrl: './music-festival.component.html',
  styleUrls: ['./music-festival.component.scss']
})
export class MusicFestivalComponent {

  banner: { image: string, title: string, color: string };
  festivals: Festival[];

  constructor(
    private authService: AuthorizationService
  ) {
    this.banner = { image: '../../../../assets/images/showcasing/MF.jpg', title: 'Music Festival', color: "#e64675" }
    this.getFestivals();
  }

  getFestivals() {
    this.authService.getReq('music_festivals').subscribe((data: any[]) => {
      this.festivals = data.map((festival: any) => {
        return {
          title: festival.acf.title ? festival.acf.title : '',
          coverPicture: festival.acf.cover_picture ? festival.acf.cover_picture : '',
          coverYoutube: festival.acf.cover_youtube ? festival.acf.cover_youtube : '',
          about: festival.acf.about ? festival.acf.about : '',
          stories: festival.acf.stories ? festival.acf.stories.map((story: any) => {
            return {
              title: story.title,
              image: story.image,
              link: story.link
            }
          }) : [],
          photoArchives: festival.acf.photo_archive ? festival.acf.photo_archive.map((photo: any) => { return photo.image }) : [],
          videoArchives: festival.acf.video_archives ? festival.acf.video_archives.map((video: any) => { return video.youtube_link }) : [],
          printMediaArchives: festival.acf.print_media_archives ? festival.acf.print_media_archives.map((print: any) => { return { image: print.image, title: print.title } }) : [],
          partners: festival.acf.partners ? festival.acf.partners.map((partner: any) => {
            return { image: partner.image, title: partner.title }
          }) : []
        }
      });
    })
  }

}

export interface Festival {
  title: string,
  coverPicture: string,
  coverYoutube: string,
  about: string,
  stories: { title: string, image: string, link: string }[],
  photoArchives: string[],
  videoArchives: string[],
  printMediaArchives: { image: string, title: string }[],
  partners: { image: string, title: string }[]
}

