<app-styled-banner
  [banner]="{'image':'../../../../assets/images/documentaries/documentary-banner.jpg', 'title': 'Documentaries', color: '#e64675'}">
</app-styled-banner>

<div class="container my-5">
  <h2 class="row mt-5">Documentary Library</h2>

  <div class="row mt-5" *ngIf="documentaries">
    <div class="col-lg-4 col-md-6 pl-0 pr-3 mt=0 mb-3"
      *ngFor="let doc of documentaries| paginate: { itemsPerPage: 12, currentPage: currentPage }">

      <div class="bg-grey doc" (click)="showModal(doc.videos)">
        <div class="embed-responsive embed-responsive-16by9">
          <img class="px-0 embed-responsive-item" [src]="getVidThumbnailUrl(doc.videos[0].link)">
        </div>
        <img src="./assets/images/common/ic_youtube.webp" class="yt mb-auto">
        <h4 class="text-center mt-4" style="height: 72px;">{{doc.title}}</h4>
      </div>
    </div>

  </div>
</div>

<app-image-modal *ngIf="modalVids" [images]="modalVids" [index]="0" (close)="hideModal()"></app-image-modal>

<div class="text-center mb-5">
  <pagination-controls (pageChange)="currentPage = $event; scrollToTop()" autoHide="true">
  </pagination-controls>
</div>
