<div class="container">
  <h2>Workshop</h2>
</div>
<div class="container-fluid">
  <img class="w-100" src="../../../../assets/images/home/workshop-banner.png" alt="">
</div>
<div class="container">
  <br><br>
  <p class="content-width m-auto text-center">We organize workshops to transmit heritage skills through Guru Shishya
    Parampara-based
    training between local
    exponents and pupils, teach folk music to youth, and advanced skills training on aspects like vocal,
    instrumentation,
    recording, sound engineering. We invite national. international exponents for the same.
  </p><br>
  <h4 class="bg-orange py-2 px-4 m-auto" style="width: fit-content; cursor: pointer;"
    (click)='authService.navigateToPage("workshops")'>View More</h4>
</div>
<app-grey-seperator></app-grey-seperator>
<div class="container">
  <h2>Folks of Bengal App</h2>
  <div class="row">
    <div class="col-md-4 my-4">
      <img class="w-100 side-image" src="../../../../assets/images/home/App.jpg" alt="">
    </div>
    <div class="col-md-8">
      <div class="album-content my-4">
        <ngx-slick-carousel *ngIf='albums && albums.length' class="carousel" #slickModal="slick-carousel"
          [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
          (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let album of albums" class="slide"
            (click)="authService.navigateToPage('albums', {albumId:album.id})">
            <img class="w-100 px-4" src="{{album.image}}" alt="">
          </div>
        </ngx-slick-carousel>
      </div>
      <div class="bg-grey p-4 my-4">
        <p>Folks of Bengal is an app available at Google playstore for Android users and can listen to thousands of folk
          music of Bengal featuring both rural traditional artists and urban artists covering various music genres
          including Baul, Bhatiyali, Bhawaiya and Folk fusion.</p>
        <div class="text-right mt-4">
          <ul class="list-inline">
            <li class="list-inline-item">
              <h4 class="bg-orange p-2 text-center"> <a
                  href="https://play.google.com/store/apps/details?id=folks.of.bengal.bnc.app"
                  target="blank">Download App</a></h4>
            </li>
            <li class="list-inline-item">
              <h4 class="bg-orange p-2 text-center" (click)="authService.navigateToPage('albums')">View More</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
