<div class="container my-5">
  <div class="row">
    <h2 class="col-sm-5 mb-4 px-4">{{showingFb ? 'Facebook' : 'Instagram'}}</h2>

    <div class="col-sm-7 d-flex flex-row-reverse pb-5">
      <button mdbWavesEffect class="mx-2" [ngClass]="showingFb ? '' : 'selected'" (click)="showFb(false)">
        Instagram
      </button>
      <button mdbWavesEffect class="mx-2" [ngClass]="showingFb ? 'selected' : ''" (click)="showFb(true)">
        Facebook
      </button>
    </div>

  </div>

  <div *ngIf="!showingFb" class="ig-holder">
    <div *ngFor="let post of instaPosts | paginate: { itemsPerPage: 6, currentPage: currentPage }"
      [innerHTML]="post.embed_content" class="ig-post"></div>
  </div>

  <div *ngIf="showingFb" class="row">
    <div *ngFor="let post of fbPosts | paginate: { itemsPerPage: 6, currentPage: currentPage }" class="col-md-6 my-3">
      <div class="fb-holder">
        <div class="fb-iframe" [innerHTML]="post.embed_content"></div>
        <p class="text-center mx-auto">{{post.title}}</p>
      </div>
    </div>
  </div>

  <div class="text-center mt-4">
    <pagination-controls (pageChange)="currentPage = $event" autoHide="true"></pagination-controls>
  </div>

</div>