<app-styled-banner *ngIf='banner' [banner]='banner'></app-styled-banner>
<br>
<div *ngFor='let artist of artists | paginate: { itemsPerPage: 5, currentPage: p }' class="my-4">
  <app-artist-holder [artist]='artist'></app-artist-holder>
</div>

<div *ngIf="artists" class="text-center">
  <pagination-controls (pageChange)="handlePage($event)" autoHide="true">
  </pagination-controls>
  <br>
</div>