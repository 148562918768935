<app-styled-banner *ngIf='banner' [banner]='banner'></app-styled-banner>

<div class="container my-5">

  <div class="row my-4">
    <!-- <div class="col-md-3"></div> -->
    <div class="col-md-3 text-center">
      <label for="genreselect">Genre</label>
      <select class="browser-default custom-select ml-2" #genreselect
        (change)="handleSelect('Genre', genreselect.value)">
        <option *ngFor='let genre of genres' value="{{genre}}">{{genre}}</option>
      </select>
    </div>
    <div class="col-md-3 text-center">
      <label for="stateselect">States</label>
      <select class="browser-default custom-select ml-2" #stateselect
        (change)="handleSelect('State', stateselect.value)">
        <option *ngFor='let state of states' value="{{state}}">{{state}}</option>
      </select>
    </div>
    <div class="col-md-3 text-center">
      <label for="districtselect">District</label>
      <select class="browser-default custom-select ml-2" #districtselect
        (change)="handleSelect('District', districtselect.value)">
        <option *ngFor='let district of districts' value="{{district}}">{{district}}</option>
      </select>
    </div>
    <div class="col-md-3 text-center">
      <label for="villageselect">Village</label>
      <select class="browser-default custom-select ml-2" #villageselect
        (change)="handleSelect('Village', villageselect.value)">
        <option *ngFor='let village of villages' value="{{village}}">{{village}}</option>
      </select>
    </div>
  </div>
  <br>
  <table mdbTable *ngIf="filteredDirectories && filteredDirectories.length > 0">
    <thead>
      <tr>
        <th *ngFor="let header of headers" scope="col" class="text-white py-1">{{header}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let person of filteredDirectories | paginate: { itemsPerPage: 10, currentPage: currentPage }">
        <td class="p-0 img-holder"><img *ngIf="person.image" [src]="person.image" alt="person image"></td>
        <td>{{person.name}}</td>
        <td>{{person.genre}}</td>
        <td>{{person.village}}</td>
        <td>{{person.state}}</td>
        <td>{{person.district}}</td>
        <td>{{person.phone}}</td>
      </tr>
    </tbody>
  </table>
</div>

<h4 class="text-center" *ngIf="!directories">Please wait</h4>

<h4 class="text-center" *ngIf="filteredDirectories && !filteredDirectories.length">No
  Directories Found!</h4>

<div class="text-center mb-5">
  <pagination-controls (pageChange)="handlePage($event)" autoHide="true">
  </pagination-controls>
</div>