<app-styled-banner [banner]='banner'></app-styled-banner>
<div class="container-fluid">
  <div class="container">
    <div class="content-width text-center mx-auto my-4">
      <p>banglanatak dot com is a social enterprise established in 2000 working across India to synergize cultural and economic development. Our flagship initiative Art for Life (AFL) is a unique model on Culture and Development. addressing SDGs and social inclusion, using Intangible Cultural Heritage is a success story and a live case study in Sustainable Development & Tourism and has got global recognition and accreditation from UNESCQ UNWTO and UN ECOSOC. With this background, we launched MusiCal to link the folk musicians to the world and support youths with skills and resources in developing career and business opportunities and protect artists' rights. Our focus is folk music, including traditional folk and urban folk with acoustic instruments. We also started Peace Music Festival in Kolkata, called Sur Jahan (previously known as Sufi Sutra) and the festival has evolved as a mufti-city festival, and a destination for music lovers and musicians worldwide. At MusiCal, we promote Cultural Exchange and host music residencies with Int'l musicians. We also release music albums of traditional rural and urban folk artists, publish books and make documentaries on folk music.
      </p>
    </div>
  </div>
  <!-- <app-grey-seperator></app-grey-seperator> -->
<!--   <div class="container text-center mb-4">
    <h4>Partners</h4>
    <br>
    <ul class="list-inline" class="m-auto" style="width: fit-content;">
      <li class="list-inline-item"><img src="../../../assets/images/about-us/partner-logo1.png" alt=""></li>
      <li class="list-inline-item"><img src="../../../assets/images/about-us/partner-logo2.png" alt=""></li>
    </ul>
  </div> -->
</div>