import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './routes/home/home.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { HomeUpperComponent } from './components/home/home-upper/home-upper.component';
import { UpcomingEventsComponent } from './components/home/upcoming-events/upcoming-events.component';
import { GreySeperatorComponent } from './components/common/grey-seperator/grey-seperator.component';
import { HomeResidencyComponent } from './components/home/home-residency/home-residency.component';
import { HomeSurJahanComponent } from './components/home/home-sur-jahan/home-sur-jahan.component';
import { HomeGenreComponent } from './components/home/home-genre/home-genre.component';
import { HomeFolksOfBengalComponent } from './components/home/home-folks-of-bengal/home-folks-of-bengal.component';
import { HomeEventSocialMediaComponent } from './components/home/home-event-social-media/home-event-social-media.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { AboutUsComponent } from './routes/about-us/about-us.component';
import { StyledBannerComponent } from './components/common/styled-banner/styled-banner.component';
import { ResidencyComponent } from './routes/residency/residency.component';
import { ResidenciesComponent } from './components/residency/residencies/residencies.component';
import { ImageModalComponent } from './components/common/image-modal/image-modal.component';
import { ResidencyImageHolderComponent } from './components/residency/image-holder/residency-image-holder.component';
import { NgxPaginationModule } from "ngx-pagination";
import { InternationalEventsComponent } from './routes/international-events/international-events.component';
import { InternationalEventGalleryComponent } from './components/international-events/international-event-gallery/international-event-gallery.component';
import { OtherEventsComponent } from './routes/other-events/other-events.component';
import { InternationalEventImageHolderComponent } from './components/international-events/international-event-image-holder/international-event-image-holder.component';
import { MusicFestivalComponent } from './routes/music-festival/music-festival.component';
import { MusicFestivalsComponent } from './components/music-festival/music-festival-gallery/music-festivals.component';
import { MusicFestivalSingleComponent } from './components/music-festival/music-festival-single/music-festival-single.component';
import { ImageHolderGenericComponent } from './components/common/image-holder-generic/image-holder-generic.component';
import { MusicSpaceComponent } from './routes/music-space/music-space.component';
import { MusicSpaceFacebookComponent } from './components/music-space/music-space-facebook/music-space-facebook.component';
import { AlbumComponent } from './routes/album/album.component';
import { AlbumGalleryComponent } from "./components/albums/album-gallery/album-gallery.component";
import { AlbumCdHolderComponent } from './components/albums/album-cd-holder/album-cd-holder.component';
import { GenreComponent } from './routes/genre/genre.component';
import { SocialComponent } from './routes/social/social.component';
import { SocialWallComponent } from './components/social-wall/social-wall.component';
import { MusicSpaceGalleryComponent } from './routes/music-space-gallery/music-space-gallery.component';
import { DocumentariesComponent } from './routes/documentaries/documentaries.component';
import { BooksComponent } from './routes/books/books.component';
import { DirectoryComponent } from './routes/directory/directory.component';
import { SpaceGalleryHolderComponent } from './components/music-space-gallery/space-gallery-holder/space-gallery-holder.component';
import { BooksModalComponent } from './components/books/books-modal/books-modal.component';
import { WorkshopsComponent } from './routes/workshops/workshops.component';
import { WorkshopGalleryHolderComponent } from './components/workshops/workshop-gallery-holder/workshop-gallery-holder.component';
import { ArtistsComponent } from './routes/artists/artists.component';
import { ArtistHolderComponent } from './components/artists/artist-holder/artist-holder.component';
import { SiderSocialHandlesComponent } from './components/common/sider-social-handles/sider-social-handles.component';
import { FaceookLivesComponent } from './routes/faceook-lives/faceook-lives.component';
import { HomeSafarTunesComponent } from './components/home/home-safar-tunes/home-safar-tunes.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    HomeUpperComponent,
    UpcomingEventsComponent,
    GreySeperatorComponent,
    HomeResidencyComponent,
    HomeSurJahanComponent,
    HomeGenreComponent,
    HomeFolksOfBengalComponent,
    HomeEventSocialMediaComponent,
    FooterComponent,
    AboutUsComponent,
    StyledBannerComponent,
    ResidencyComponent,
    ResidenciesComponent,
    ImageModalComponent,
    ResidencyImageHolderComponent,
    InternationalEventsComponent,
    InternationalEventGalleryComponent,
    OtherEventsComponent,
    InternationalEventImageHolderComponent,
    MusicFestivalComponent,
    MusicFestivalsComponent,
    MusicFestivalSingleComponent,
    ImageHolderGenericComponent,
    MusicSpaceComponent,
    MusicSpaceFacebookComponent,
    AlbumComponent,
    AlbumGalleryComponent,
    AlbumCdHolderComponent,
    GenreComponent,
    SocialComponent,
    SocialWallComponent,
    MusicSpaceGalleryComponent,
    DocumentariesComponent,
    BooksComponent,
    DirectoryComponent,
    SpaceGalleryHolderComponent,
    BooksModalComponent,
    WorkshopsComponent,
    WorkshopGalleryHolderComponent,
    ArtistsComponent,
    ArtistHolderComponent,
    SiderSocialHandlesComponent,
    FaceookLivesComponent,
    HomeSafarTunesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgxPaginationModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
