import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './routes/home/home.component';
import { AboutUsComponent } from './routes/about-us/about-us.component';
import { ResidencyComponent } from './routes/residency/residency.component';
import { InternationalEventsComponent } from './routes/international-events/international-events.component';
import { OtherEventsComponent } from './routes/other-events/other-events.component';
import { MusicFestivalComponent } from './routes/music-festival/music-festival.component';
import { MusicSpaceComponent } from './routes/music-space/music-space.component';
import { AlbumComponent } from "./routes/album/album.component";
import { GenreComponent } from './routes/genre/genre.component';
import { SocialComponent } from "./routes/social/social.component";
import { MusicSpaceGalleryComponent } from './routes/music-space-gallery/music-space-gallery.component';
import { DocumentariesComponent } from './routes/documentaries/documentaries.component';
import { BooksComponent } from './routes/books/books.component';
import { DirectoryComponent } from './routes/directory/directory.component';
import { WorkshopsComponent } from './routes/workshops/workshops.component';
import { ArtistsComponent } from './routes/artists/artists.component';
import { FaceookLivesComponent } from './routes/faceook-lives/faceook-lives.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'residency', redirectTo: 'residency/', pathMatch: 'full' },
  { path: 'residency/:residencyId', component: ResidencyComponent },
  { path: 'international-events', component: InternationalEventsComponent },
  { path: 'other-events', component: OtherEventsComponent },
  { path: 'music-festivals', component: MusicFestivalComponent },
  { path: 'music-space', component: MusicSpaceComponent },
  { path: 'music-space/:id', component: MusicSpaceGalleryComponent },
  { path: 'albums', redirectTo: 'albums/', pathMatch: 'full' },
  { path: 'albums/:artistId', component: AlbumComponent },
  { path: 'genre/:id', component: GenreComponent },
  { path: 'social', component: SocialComponent },
  { path: 'documentaries', component: DocumentariesComponent },
  { path: 'books', component: BooksComponent },
  { path: 'directory', component: DirectoryComponent },
  { path: 'workshops', component: WorkshopsComponent },
  { path: 'artists/:genreId', component: ArtistsComponent },
  { path: 'facebook-live', component: FaceookLivesComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
