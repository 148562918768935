import {AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {Social} from "../../routes/social/social.component";

declare namespace instgrm {
  namespace Embeds {
    function process(): void;
  }
}

@Component({
  selector: 'app-social-wall',
  templateUrl: './social-wall.component.html',
  styleUrls: ['./social-wall.component.scss']
})
export class SocialWallComponent implements OnInit, AfterViewChecked {

  @Input() instaPosts: Social[];
  @Input() fbPosts: Social[];
  showingFb = true;
  currentPage = 1;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    if (!this.showingFb && window['instgrm']) {
      instgrm.Embeds.process();
    }
  }

  showFb(isFb: boolean): void {
    this.showingFb = isFb;
    this.currentPage = 1;
  }

}
