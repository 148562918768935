<app-styled-banner [banner]='banner'></app-styled-banner>
<app-grey-seperator></app-grey-seperator>
<app-music-space-facebook></app-music-space-facebook>
<div *ngFor='let space of spaces; let i = index'>
  <app-grey-seperator></app-grey-seperator>
  <div class="container">
    <div class="row">
      <div class="col-md-6 p-2">
        <div class="bg-grey p-2">
          <img class="w-100" src="{{space.coverPicture}}" alt="">
        </div>
      </div>
      <div class="col-md-6 p-2">
        <div class="bg-grey h-100 p-4" style="position: relative;">
          <h2>{{space.title}}</h2>
          <br>
          <p>{{space.intro}}</p>
          <h4 class="bg-orange view-more p-2 mb-0" (click)='authService.navigateToPage("music-space/"+space.id)'>View
            More</h4>
        </div>
      </div>
    </div>
  </div>
</div>
<br><br>