import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-international-event-image-holder',
  templateUrl: './international-event-image-holder.component.html',
  styleUrls: ['./international-event-image-holder.component.scss']
})
export class InternationalEventImageHolderComponent implements OnInit {

  @Input() event: { title: string, date: string, endDate: string, images: string[], content: string };
  imageIndex = 0;
  isModalActive: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  nextImage() {
    this.imageIndex = (this.imageIndex + 1) % this.event.images.length;
  }

  prevImage() {
    this.imageIndex = this.imageIndex < 1 ? this.event.images.length - 1 : this.imageIndex - 1;
  }

}
