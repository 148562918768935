import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-workshops',
  templateUrl: './workshops.component.html',
  styleUrls: ['./workshops.component.scss']
})
export class WorkshopsComponent {

  workshops: { title: string, about: string, date: string, gurus: string, medias: { type: string, media: string }[] }[];
  constructor(
    private authService: AuthorizationService
  ) {
    this.getWorkshops();
  }

  getWorkshops() {
    this.authService.getReq('workshops').subscribe((data: any[]) => {
      this.workshops = data.map((workshop: any) => {
        return {
          title: workshop.acf.title,
          about: workshop.acf.about,
          date: workshop.acf.date,
          gurus: workshop.acf.gurus.map((guru: any) => {
            return guru.guru_name;
          }).join(', '),
          medias: workshop.acf.medias.map((media: any) => {
            if (media.type === 'Image') {
              return { type: 'Image', media: media.image };
            } else if (media.type === 'Youtube') {
              return { type: 'Youtube', media: media.youtube_link };
            }
          })
        }
      })
    })
  }

}
