<div class="container my-4">
  <div class="row bg-grey">
    <div class="col-md-5 p-2">
      <img class="w-100" src="{{artist.cover}}" alt="">
    </div>
    <div class="col-md-7 p-5">
      <h4>{{artist.name}}</h4>
      <p>{{artist.intro}}</p>
      <br>
      <ul class="list-inline">
        <li class="list-inline-item" *ngIf='artist.images'>
          <h4 class="bg-orange px-4 py-2 mx-2" (click)='openModal(artist.images, 0)'>Photos</h4>
        </li>
        <li class="list-inline-item" *ngIf='artist.videos'>
          <h4 class="bg-orange px-4 py-2 mx-2" (click)='openModal(artist.videos, 0)'>Videos</h4>
        </li>
        <li class="list-inline-item">
          <h4 class="bg-orange px-4 py-2 mx-2" (click)='authService.navigateToPage("albums/" + artist.id)'>Albums</h4>
        </li>
      </ul>
    </div>
  </div>
</div>

<app-image-modal *ngIf="modalIndex !== null && modalIndex !== undefined" [images]="modalImages" [index]="modalIndex"
  (close)="closeModal()"></app-image-modal>