import {Component, Input, OnInit} from '@angular/core';
import {Festival} from "src/app/routes/music-festival/music-festival.component";

@Component({
  selector: 'app-music-festivals',
  templateUrl: './music-festivals.component.html',
  styleUrls: ['./music-festivals.component.scss']
})
export class MusicFestivalsComponent implements OnInit {

  @Input() festivals: Festival [];
  index = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

  nextFest() {
    this.index = (this.index + 1) % this.festivals.length;
  }

  prevFest() {
    this.index = this.index < 1 ? this.festivals.length - 1 : this.index - 1;
  }

}
