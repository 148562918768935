import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-international-events',
  templateUrl: './international-events.component.html',
  styleUrls: ['./international-events.component.scss']
})
export class InternationalEventsComponent {

  banner: { image: string, title: string, color: string };
  events: { title: string, date: string, endDate: Date, images: string[], content: string }[];
  constructor(
    private authService: AuthorizationService
  ) {
    this.banner = { image: '../../../../assets/images/showcasing/INT.jpg', title: 'International Events', color: "#e64675" };
    this.getEvents();
  }

  getEvents() {
    this.authService.getReq('international_events?per_page=100').subscribe((data: any[]) => {
      this.events = data.map((event: any) => {
        return {
          title: event.acf.title ? event.acf.title : '',
          date: event.acf.date ? event.acf.date : '',
          endDate: event.acf.end_date ? new Date(event.acf.end_date) : new Date('2000-01-01'),
          content: event.acf.content ? event.acf.content : '',
          images: event.acf.images ? event.acf.images.map((image: any) => {
            return image.image
          }) : []
        }
      }).sort((a, b) => ((a.endDate > b.endDate) ? -1 : 1));
    })
  }

}
