<div class="container my-4" *ngIf='workshop'>
  <div class="row">
    <div class="col-md-6 p-2">
      <div class="p-5 bg-dark-grey content">
        <h2>{{workshop.title}}</h2>
        <p style="font-style: italic;">{{workshop.date}}</p>
        <br>
        <p *ngIf='workshop.gurus' class="orange-text">Gurus</p>
        <p *ngIf='workshop.gurus'>{{workshop.gurus}}</p>
        <br>
        <p class="orange-text">Workshop Detail</p>
        <p>{{workshop.about}}</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-6 p-2 bg-grey" (click)='openModal(workshop.medias, i)'
          *ngFor='let media of workshop.medias | slice: 0:4; let i = index;'>
          <div class="media-holder"
            [style.background-image]="media.type === 'Image' ? 'url('+media.media+')' : 'url(' + youtubeMedia + ')'">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-image-modal *ngIf="modalIndex !== null && modalIndex !== undefined" [images]="modalImages" [index]="modalIndex"
  (close)="closeModal()"></app-image-modal>