import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Festival } from "src/app/routes/music-festival/music-festival.component";
import { SafeResourceUrl } from "@angular/platform-browser";
import { AuthorizationService } from "src/app/services/authorization.service";

@Component({
  selector: 'app-music-festival-single',
  templateUrl: './music-festival-single.component.html',
  styleUrls: ['./music-festival-single.component.scss']
})
export class MusicFestivalSingleComponent implements OnInit {

  @Input() festival: Festival;
  @Output() nextFest = new EventEmitter<any>();
  @Output() prevFest = new EventEmitter<any>();
  coverYT: SafeResourceUrl;
  modalUrls: string[];
  slideConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 4, slidesToScroll: 1 }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 3, slidesToScroll: 2
        }
      }, {
        breakpoint: 600, settings: {
          slidesToShow: 2, slidesToScroll: 1
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }
    ]
  };

  constructor(private authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
    this.coverYT = this.authorizationService.sanatizeUrl(this.festival.coverYoutube);
  }

  getPicsFromNested(nested: { image: string, title: string }[]): string[] {
    return nested.map(archive => archive.image);
  }

  openUrl(url: string): void {
    window.open(url);
  }

  showModal(urls: string[]): void {
    this.modalUrls = urls;
  }

  closeModal(): void {
    this.modalUrls = null;
  }


  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }
}
