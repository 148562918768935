import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-international-event-gallery',
  templateUrl: './international-event-gallery.component.html',
  styleUrls: ['./international-event-gallery.component.scss']
})
export class InternationalEventGalleryComponent {

  @Input() events: { title: string, date: string, endDate: string, images: string[], content: string }[];
  currentPage = 1;

  constructor() {
  }

  handlePageChange() {
    window.scrollTo({ top: 500 });
  }


}
