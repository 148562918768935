<div class="container">
  <h2>Event Gallery</h2>
  <div class="row">
    <div class="col-md-3 p-1" *ngFor='let event of events'>
      <div class="w-100 p-2 bg-grey">
        <img class="w-100" src="{{event.image}}" alt="">
      </div>
      <div class="mt-2 p-2 text-center bg-grey">
        <h4>{{event.name}}</h4>
        <a (click)='authService.navigateToPage(event.link)'>
          <p>View More</p>
        </a>
      </div>
    </div>
  </div>
</div>
<app-grey-seperator></app-grey-seperator>
<div class="container">
  <h2>Social Wall</h2>
  <div class="row">
    <div class="col-md-6">
      <div class="embed-responsive embed-responsive-4by3">
        <iframe *ngIf='socialIndex===0' class="embed-responsive-item"
          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbncmusical&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=419266588753145"
          style="border:none; overflow:hidden; margin: auto;" scrolling="no" frameborder="0" allowTransparency="true"
          allow="encrypted-media"></iframe>
        <iframe *ngIf='socialIndex===1' class="embed-responsive-item"
          src="https://www.powr.io/instagram-feed/u/45abad56_1587393112" frameborder="0"></iframe>
        <iframe *ngIf='socialIndex===2' width="560" height="315" class="embed-responsive-item"
          src="https://www.youtube.com/embed/videoseries?list=PL72v43cDaDgnkUcMz0kDZyPh58b6Zr6g6" frameborder="0"
          allow="autoplay; encrypted-media" allowfullscreen></iframe>
      </div>
    </div>
    <div class="col-md-6 text-center">
      <div class="all-center">
        <h4>Follow MusiCal</h4>
        <br>
        <h4>MusiCal is an initiative of banglanatak dot com to promote music entreprenurship</h4>
        <br>
        <ul class="list-inline">
          <li class="list-inline-item">
            <div class="icon" [class.active]='socialIndex===0' style="border-color: #3b5998;" (click)='socialIndex=0'>
              <i class="fab fa-facebook-square" style="color: #3b5998;"></i>
            </div>
          </li>
          <li class="list-inline-item">
            <div class="icon" [class.active]='socialIndex===1' style="border-color: #c32aa3;" (click)='socialIndex=1'>
              <i class="fab fa-instagram-square" style="color: #c32aa3;"></i>
            </div>
          </li>
          <li class="list-inline-item">
            <div class="icon" [class.active]='socialIndex===2' style="border-color: #FF0000;" (click)='socialIndex=2'>
              <i class="fab fa-youtube" style="color: #FF0000;"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<app-grey-seperator>
  <div class="container">
    <h2>Media</h2>
    <br>
    <div class=""></div>
  </div>
</app-grey-seperator>