import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-home-event-social-media',
  templateUrl: './home-event-social-media.component.html',
  styleUrls: ['./home-event-social-media.component.scss']
})
export class HomeEventSocialMediaComponent implements OnInit {
  // Will be used to determine which social media is selected.
  socialIndex: number = 0;

  events = [
    { name: 'International', image: '../../../../assets/images/home/event-international.jpg', link: 'international-events' },
    { name: 'Music Space', image: '../../../../assets/images/home/event-music-space.jpg', link: 'music-space' },
    { name: 'Music Festival', image: '../../../../assets/images/home/event-music-festival.jpg', link: 'music-festivals' },
    { name: 'Other', image: '../../../../assets/images/home/event-other.jpg', link: 'other-events' }
  ]

  constructor(
    public authService: AuthorizationService
  ) { }

  ngOnInit(): void {
  }

}
