<div class="container">
  <div class="row my-4">
    <div class="col-md-6">
      <!-- <div style="width: 100%;">
        <div class="fb-page" data-href="https://www.facebook.com/bncmusical" data-tabs="timeline" data-width="500"
          data-height="400" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false"
          data-show-facepile="true">
          <blockquote cite="https://www.facebook.com/bncmusical" class="fb-xfbml-parse-ignore"><a
              href="https://www.facebook.com/bncmusical">MusiCal</a></blockquote>
        </div>
      </div> -->
      <div class="bg-grey p-2">
        <img src="../../../../assets/images/showcasing/fb-live.jpg" class="w-100" alt="">
      </div>
    </div>
    <div class="col-md-6 px-2">
      <div class="bg-grey p-4 h-100" style="position: relative;">
        <h2>MusiCal Facebook Live</h2>
        <br>
        <p>Our response to lockdown started with organising Facebook live sessions every evening  at our bncmusical page (hyperlink the Facebook page link) starting from 29 March 2020. With this, we aimed at creating an opportunity for rural traditional and urban artists in self promotion. 35-45 minutes were dedicated to the performance while reaching out to an engaging audience across the globe.</p>
        <h4 class="bg-orange view-more p-2 mb-0" (click)='authService.navigateToPage("facebook-live")'>View More</h4>
      </div>
    </div>
  </div>
</div>
