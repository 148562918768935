import { Component, OnInit, ViewChild } from '@angular/core';
import { faFacebook, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faDownload, faNewspaper, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { ModalDirective } from 'angular-bootstrap-md';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sider-social-handles',
  templateUrl: './sider-social-handles.component.html',
  styleUrls: ['./sider-social-handles.component.scss']
})
export class SiderSocialHandlesComponent implements OnInit {

  @ViewChild('basicModal', { static: false }) basicModal: ModalDirective;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faYoutube = faYoutube;
  faGlobe = faGlobe;
  faDownload = faDownload;
  faTwitter = faTwitter;
  faNewspaper = faNewspaper;
  faFileDownload = faFileDownload;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  showSocials(input: string) {
    if (input === 'Facebook') {
      window.open('https://www.facebook.com/bncmusical/', 'blank');
    } else if (input === 'Instagram') {
      window.open('https://www.instagram.com/_surjahan_/', 'blank');
    } else if (input === 'Youtube') {
      window.open('https://www.youtube.com/channel/UC5D676XcAQIskU_wv3CC_oA', 'blank');
    }
  }
}
