import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-grey-seperator',
  templateUrl: './grey-seperator.component.html',
  styleUrls: ['./grey-seperator.component.scss']
})
export class GreySeperatorComponent implements OnInit {

  @Input() caption: string;

  constructor() { }

  ngOnInit(): void {
  }

}
