import { Component } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-faceook-lives',
  templateUrl: './faceook-lives.component.html',
  styleUrls: ['./faceook-lives.component.scss']
})
export class FaceookLivesComponent {

  lives: { id: number, title: string, embed_content: SafeResourceUrl }[];
  currentPage = 1;

  constructor(
    private authService: AuthorizationService
  ) {
    this.getLives();
  }

  getLives() {
    const limit:string = '500';
    let params = new HttpParams().set("per_page", limit);
    this.authService.getReq('facebook_lives', false, params).subscribe((data: any[]) => {
      this.lives = data.map((live: any) => {
        return {
          id: live.id ? live.id : false,
          title: live.acf.title ? live.acf.title : false,
          embed_content: live.acf.embed_post ? this.authService.sanatizeHtml(live.acf.embed_post) : false
        }
      }).filter(live => live);
    })
  }

  handlePaginationController(input: any) {
    this.currentPage = input;
    window.scrollTo({ top: 0 });
  }

}
