<app-styled-banner *ngIf='genre' [banner]='{"image": genre.bannerImage, "title": genre.name, color: "#e64675"}'></app-styled-banner>
<div class="container text-center" *ngIf='genre'>
  <br>
  <p class="content-width m-auto">{{genre.intro}}</p>
  <br>
  <h4 style="width: fit-content; cursor: pointer;" class="bg-orange px-4 py-2 m-auto"
    (click)='authService.navigateToPage("artists/" + genre.id )'>{{genre.name}} Featured Artists
  </h4>
  <br>
</div>

<div *ngIf='genre && genre.introImage'>
  <div class="container-fluid" *ngIf='genre.introImageWidth === "Full"'>
    <img class="w-100" src="{{genre.introImage}}" alt="">
  </div>
  <div class="container" *ngIf='genre.introImageWidth === "Small"'>
    <img style="display: block; max-width: 100%;" class="m-auto" src="{{genre.introImage}}" alt="">
  </div>
</div>



<div *ngIf='genre && genre.contents'>
  <div *ngFor='let content of genre.contents; let i = index;'>

    <!-- Normal Content Start Here -->
    <div>
      <!-- Section seperator here -->
      <div class="container">
        <img class="w-100" src="../../../assets/images/genre/Separator.png" alt="">
      </div>

      <!-- If image is to be positioned top -->
      <div *ngIf='content.isImage && (content.imagePosition==="Top")'>
        <div class="container-fluid" *ngIf='content.imageWidth==="Full"'>
          <img class="w-100" src="{{content.image}}" alt="">
        </div>
        <div class="container" *ngIf='(content.imageWidth==="Half") || (content.imageWidth==="Small")'>
          <img *ngIf='content.imageWidth==="Half"' class="w-100" src="{{content.image}}" alt="">
          <img style="display: block; max-width: 100%;" *ngIf='content.imageWidth==="Small"' class="m-auto"
            src="{{content.image}}" alt="">
        </div>
      </div>

      <!-- Text Content Here -->
      <div class="container">
        <div class="text-center mb-2">
          <h2>{{content.title}}</h2>
        </div>
        <div [innerHTML]='content.content'></div>
      </div>

      <!-- If image is to be positioned bottom -->
      <div *ngIf='content.isImage && (content.imagePosition==="Bottom")'>
        <div class="container-fluid" *ngIf='content.imageWidth==="Full"'>
          <img class="w-100" src="{{content.image}}" alt="">
        </div>
        <div class="container" *ngIf='(content.imageWidth==="Half") || (content.imageWidth==="Small")'>
          <img *ngIf='content.imageWidth==="Half"' class="w-100" src="{{content.image}}" alt="">
          <img style="display: block; max-width: 100%;" *ngIf='content.imageWidth==="Small"' class="m-auto"
            src="{{content.image}}" alt="">
        </div>
      </div>
    </div>
    <!-- Normal Content End here -->

    <!-- Sections Content start here -->
    <div class="container">
      <br>
      <div class="row" *ngIf="sections && sectionsPosition && (sectionsPosition === (i+1))">
        <!-- {{sections | json}} -->
        <div *ngFor='let section of sections' class="my-2 px-4"
          [class]="{'col-md-4':(section.width==='One-Third'), 'col-md-6':(section.width==='Half'), 'col-md-8':(section.width==='Two-Third')}">
          <div *ngIf='section.image'>
            <img src="{{section.image}}" class="w-100" alt="">
          </div>
          <div *ngIf='!section.image'>
            <div class="text-center">
              <h4 class="border-top">{{section.title}}</h4>
            </div>
            <div [innerHTML]='section.content'></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sections Content end here -->

  </div>
</div>
<br><br>