import {Component} from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';
import {MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import {BooksModalComponent} from '../../components/books/books-modal/books-modal.component';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent {

  books: { id: number, title: string, about: string, author: string, image: string }[];
  currentPage = 1;
  modalRef: MDBModalRef;

  constructor(private authService: AuthorizationService, private modalService: MDBModalService) {
    this.getBooks();
  }

  getBooks(): void {
    this.authService.getReq('books').subscribe((data: any[]) => {
      this.books = data.map((book: any) => {
        return {
          id: book.id,
          title: book.acf.title,
          about: book.acf.about,
          author: book.acf.author,
          image: book.acf.image
        };
      });
    });
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  showModal(book): void {
    this.modalRef = this.modalService.show(BooksModalComponent, {data: {book}});
  }

}
