<mdb-navbar SideClass="navbar sticky-top navbar-expand-lg navbar-light semibold-text">
  <mdb-navbar-brand>
    <a (click)='authService.navigateToPage("")' class="navbar-brand">
      <img src="../../../../assets/images/common/bncmusical-logo.png" alt="" #logo>
    </a>
  </mdb-navbar-brand>
  <links>
    <ul class="navbar-nav ml-auto text-center">
      <li class="nav-item" (click)='authService.navigateToPage("")'>
        <a class="nav-link waves-light" mdbWavesEffect>Home</a>
      </li>
      <li class="nav-item" (click)='authService.navigateToPage("about-us")'>
        <a class="nav-link waves-light" mdbWavesEffect>About</a>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>Showcasing<span
            class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" (click)='authService.navigateToPage("music-space")'>Music Space</a>
          <a class="dropdown-item waves-light" (click)='authService.navigateToPage("music-festivals")'>Music
            Festival</a>
          <a class="dropdown-item waves-light"
            (click)='authService.navigateToPage("international-events")'>International Events</a>
          <a class="dropdown-item waves-light" (click)='authService.navigateToPage("other-events")'>Other
            Events</a>
        </div>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>Genre<span
            class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a *ngFor='let genre of genres' class="dropdown-item waves-light"
            (click)='authService.navigateToPage("genre/"+genre.id)'>{{genre.name}}</a>
        </div>
      </li>
      <li class="nav-item" (click)='authService.navigateToPage("workshops")'>
        <a class="nav-link waves-light" mdbWavesEffect>Workshop</a>
      </li>
      <li class="nav-item" (click)='authService.navigateToPage("residency")'>
        <a class="nav-link waves-light" mdbWavesEffect>Residency</a>
      </li>
      <li class="nav-item dropdown" dropdown>
        <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>Publications<span
            class="caret"></span></a>
        <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
          <a class="dropdown-item waves-light" (click)='authService.navigateToPage("albums")'>Albums</a>
          <a class="dropdown-item waves-light" (click)='authService.navigateToPage("books")'>Books</a>
          <a class="dropdown-item waves-light" (click)='authService.navigateToPage("documentaries")'>Documentaries</a>
        </div>
      </li>
      <li class="nav-item" (click)='authService.navigateToPage("directory")'>
        <a class="nav-link waves-light" mdbWavesEffect>Directory</a>
      </li>
      <li class="nav-item" (click)='authService.navigateToPage("social")'>
        <a class="nav-link waves-light" mdbWavesEffect>Social Wall</a>
      </li>
      <li class="nav-item" (click)='showForm()'>
        <a class="nav-link waves-light" mdbWavesEffect>Contact</a>
      </li>
    </ul>
  </links>
</mdb-navbar>
<div mdbModal #frame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="overflow-y: auto;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body mx-3">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
        <br>
        <div class="row regular-text">

          <div class="col-md-6">
            <div class="form-container m-4">
              <h2>Write to us</h2>
              <br>
              <div class="md-form mb-5">
                <!-- <mdb-icon fas icon="user" class="prefix grey-text"></mdb-icon> -->
                <input type="text" id="form34" class="form-control" mdbInput mdbValidate
                  [formControl]="contactFormModalName">
                <label for="form34">Your name</label>
              </div>

              <div class="md-form mb-5">
                <!-- <mdb-icon fas icon="envelope" class="prefix grey-text"></mdb-icon> -->
                <input type="email" id="form29" class="form-control" mdbInput mdbValidate
                  [formControl]="contactFormModalEmail">
                <label for="form29">Your email</label>
              </div>

              <div class="md-form mb-5">
                <!-- <mdb-icon fas icon="tag" class="prefix grey-text"></mdb-icon> -->
                <input type="text" id="form32" class="form-control" mdbInput mdbValidate
                  [formControl]="contactFormModalSubject">
                <label for="form32">Subject</label>
              </div>

              <div class="md-form">
                <!-- <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon> -->
                <textarea type="text" id="form8" class="md-textarea form-control" rows="1" mdbInput mdbValidate
                  [formControl]="contactFormModalMessage"></textarea>
                <label for="form8">Your message</label>
              </div>

              <div class="text-center">
                <button mdbBtn color="unique" class="waves-light" mdbWavesEffect
                  (click)='send(); (validatingForm.status=="VALID") && frame.hide() '
                  [disabled]="validatingForm.status=='INVALID'">send
                  <mdb-icon far icon="paper-plane" class="ml-1"></mdb-icon>
                </button>
              </div>

            </div>

          </div>

          <div class="col-md-6">
            <br>
            <div class="address mt-4">
              <h3>Kolkata Office</h3>
              <p>+91 3340047484</p>
              <p>188/89, Prince Anwar Shah Road, Kolkata - 700045</p>
            </div>
            <br>
            <!-- <div class="address">
              <h3>Rajasthan Office</h3>
              <p>Krishna Niwas, 1st Floor,
                G - 15 Krishna Marg, C - Scheme, Jaipur - 302001</p>
            </div>
            <br> -->
            <!-- <div class="address">
              <h3>Delhi Office</h3>
              <p>+91 112627225</p>
              <p>E-781, C.R.Park, New Delhi - 110019</p>
            </div> -->
            <br>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>
