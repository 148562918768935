<div class="floating-sidebar">
  <!-- <div class="floating-sidebar-item">
    <fa-icon [icon]="faGlobe" class="fa-2x floating-sidebar--icon globe" (click)='showSocials("Website")'></fa-icon>
  </div> -->
  <div class="floating-sidebar-item">
    <fa-icon [icon]="faFacebook" class="fa-2x floating-sidebar--icon facebook" (click)='showSocials("Facebook")'>
    </fa-icon>
  </div>
  <div class="floating-sidebar-item">
    <fa-icon [icon]="faInstagram" class="fa-2x floating-sidebar--icon instagram" (click)='showSocials("Instagram")'>
    </fa-icon>
  </div>
  <div class="floating-sidebar-item">
    <fa-icon [icon]="faYoutube" class="fa-2x floating-sidebar--icon youtube" (click)='showSocials("Youtube")'></fa-icon>
  </div>
  <div class="floating-sidebar-item">
    <fa-icon [icon]="faFileDownload" class="fa-2x floating-sidebar--icon files" (click)='showSocials("Twitter")'>
    </fa-icon>
  </div>
</div>