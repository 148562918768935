import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from "../../services/authorization.service";
import {SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {

  banner: { image: string, title: string, color: string };
  instaPosts: Social[];
  fbPosts: Social[];

  constructor(private authService: AuthorizationService) {
    this.banner = {image: '../../../../assets/images/residency/Residency.jpg', title: 'Social Wall', color: "#e64675"}
  }

  ngOnInit(): void {
    this.setSocial();
  }

  setSocial() {
    this.authService.getReq('social_medias?per_page=1000').subscribe((data: any[]) => {

      let instaPosts: Social[] = [];
      let fbPosts: Social[] = [];

      data.forEach((item: any) => {
        if (item.acf.type === 'Instagram') {
          let embed = item.acf.embed_content.split('<script async src=\"//www.instagram.com/embed.js\"></script>')[0];
          instaPosts.push({
            id: item.id,
            title: item.acf.title,
            embed_content: this.authService.sanatizeHtml(embed)
          });
        } else if (item.acf.type === 'Facebook') {
          fbPosts.push({
            id: item.id,
            title: item.acf.title,
            embed_content: this.authService.sanatizeHtml(item.acf.embed_content)
          });
        }
      });
      this.instaPosts = instaPosts;
      this.fbPosts = fbPosts;

    });
  }

}

export interface Social {
  id: number;
  title: string;
  embed_content: SafeResourceUrl;
}
