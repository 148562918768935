import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AlbumFinal, AlbumTop} from '../../../routes/album/models';

@Component({
  selector: 'app-album-gallery',
  templateUrl: './album-gallery.component.html',
  styleUrls: ['./album-gallery.component.scss']
})
export class AlbumGalleryComponent implements OnInit, OnChanges {

  @Input() albumsBasic: AlbumTop[];
  @Input() albumsFull: AlbumFinal[];
  @Input() error: boolean;

  /**
   * If set, show details for a particular album with this ID
   */
  @Input() showDetailsId: number;

  currentScreen = {
    isCd: true,
    cdIndex: 1,
    albumIndex: 0
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showDetailsId']) {
      let detailsId = changes['showDetailsId'].currentValue;
      this.showTracksFromParam(detailsId);
    } else if (changes['albumsBasic']) {
      this.showTracksFromParam(this.showDetailsId);
    }
  }

  showTracksFromParam(albumId?: number) {
    // We'll call this again when both are ready
    if (!this.albumsBasic || albumId === null || albumId === undefined) {
      return;
    }

    let albumIndex = this.albumsBasic.findIndex(album => album.id === albumId);
    if (albumIndex > -1) {
      this.currentScreen.albumIndex = albumIndex;
      this.currentScreen.isCd = false;
    }
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  showAlbum(index: number): void {
    this.currentScreen.albumIndex = index;
    this.currentScreen.isCd = false;
    this.scrollToTop();
  }

  showCd(): void {
    this.currentScreen.isCd = true;
  }

}
