<div class="container mb-5">

  <mdb-breadcrumb class="row my-4">
    <mdb-breadcrumb-item [ngClass]="currentScreen.isCd ? 'active' : 'black-text'" (click)="showCd()">
      <h4>CD Gallery</h4>
    </mdb-breadcrumb-item>
    <mdb-breadcrumb-item *ngIf="!currentScreen.isCd" class="active">
      <h4>Album Details</h4>
    </mdb-breadcrumb-item>
  </mdb-breadcrumb>

  <!-- For albums -->
  <div *ngIf="currentScreen.isCd" class="row">
    <app-album-cd-holder
      *ngFor="let album of albumsBasic | paginate: { itemsPerPage: 10, currentPage: currentScreen.cdIndex}; let i = index"
      [album]="album" (detailsclick)="showAlbum(i + 10 * (currentScreen.cdIndex - 1))"
      class="col-lg-6 col-md-11 my-2 pl-0">
    </app-album-cd-holder>
  </div>

  <!-- For album details -->
  <div *ngIf="!currentScreen.isCd" class="row">
    <app-album-cd-holder [album]="albumsBasic[currentScreen.albumIndex]" [slimWide]="true" class="col-12 mb-4 px-0">
    </app-album-cd-holder>
    <h4 class="border p-3 mb-4 w-100">List of Songs</h4>

    <div *ngIf="albumsFull" class="w-100">
      <table *ngIf="albumsFull[currentScreen.albumIndex].tracks?.length > 0" class="w-100">
        <tr>
          <th colspan="2">Song</th>
          <th>Genre</th>
          <th>Artist</th>
        </tr>
        <tr *ngFor="let track of albumsFull[currentScreen.albumIndex].tracks; let i = index">
          <td class="track-id">{{i + 1}}</td>
          <td>{{track.title}}</td>
          <td>{{track.genre || ''}}</td>
          <td>{{track.artists ? track.artists.join(', ') : ''}}</td>
        </tr>
      </table>

      <h4 *ngIf="albumsFull[currentScreen.albumIndex].tracks?.length === 0" class="p-3 w-100 border text-center">
        There are no songs for this album
      </h4>
    </div>

  </div>

  <h4 *ngIf="!albumsFull" class="p-3 w-100 border text-center">
    {{error ? 'Failed to fetch data.' : 'Please wait...'}}
  </h4>
  <h4 *ngIf="albumsFull && !albumsFull.length" class="p-3 w-100 border text-center">
    There is nothing to show here.
  </h4>

</div>

<div *ngIf="currentScreen.isCd" class="text-center">
  <pagination-controls (pageChange)="currentScreen.cdIndex = $event; scrollToTop()" autoHide="true">
  </pagination-controls>
  <br>
</div>
