<div class="container">
  <h2>Safar Tunes App</h2>
  <div class="row">
    <div class="col-md-4 my-4">
      <img class="w-100 side-image" src="../../../../assets/images/home/App_Safar_Tunes.jpg" alt="">
    </div>
    <div class="col-md-8 my-4 side-content">
      <!--<div class="album-content my-4">
        <ngx-slick-carousel *ngIf='albums && albums.length' class="carousel" #slickModal="slick-carousel"
                            [config]="slideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
                            (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
          <div ngxSlickItem *ngFor="let album of albums" class="slide"
               (click)="authService.navigateToPage('albums', {albumId:album.id})">
            <img class="w-100 px-4" src="{{album.image}}" alt="">
          </div>
        </ngx-slick-carousel>
      </div>-->
      <div class="bg-grey h-100 p-4 d-flex flex-column">
        <p class="mt-auto">SAFAR Tunes takes you abuzz with the folk melodies of Langa, Manganiyar
          and Mir musicians of Rajasthan. Immerse yourself on a musical journey with the mystic desert musicians. Music
          tourism trails are being developed by the Department of Tourism, Govt of Rajasthan in collaboration with
          UNESCO.</p>
        <div class="text-right mt-auto">
          <ul class="list-inline">
            <li class="list-inline-item">
              <h4 class="bg-orange p-2 text-center"><a
                href="https://play.google.com/store/apps/details?id=bnc.safartunes.app"
                target="blank">Download Android App</a></h4>
            </li>
            <li class="list-inline-item">
              <h4 class="bg-orange p-2 text-center"><a
                href="https://apps.apple.com/gb/app/safar-tunes/id1543663991"
                target="blank">Download iOS App</a></h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
