import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-residency',
  templateUrl: './residency.component.html',
  styleUrls: ['./residency.component.scss']
})
export class ResidencyComponent {

  banner: { image: string, title: string, color: string };

  constructor() {
    this.banner = { image: '../../../../assets/images/residency/Residency.jpg', title: 'Residency', color: "#e64675" }
  }

}
