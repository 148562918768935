<div class="container border pr-0">
  <div class="row">
    <div [ngClass]="(slimWide ? 'col-lg-2 col-md-4 col-6 mx-auto px-2' : 'col-4 px-0') + ' py-0 d-flex my-3'">
      <img class="col px-0 my-auto" [src]="album.image">
    </div>

    <div [ngClass]="(slimWide ? 'col-lg-10' : 'col-8') + ' my-3'">
      <h4 class="mt-4">{{album.title}}</h4>

      <p *ngIf="!slimWide" class="mb-2">{{authService.trimWords(album.content, 23)}}</p>
      <p *ngIf="slimWide" class="mb-2">{{album.content}}</p>

      <p class="mb-2">CD Release Year: {{album.release_year}}</p>
      <p *ngIf="!slimWide" class="details" (click)="detailsclick.emit(null)">View Details</p>
    </div>
  </div>
</div>