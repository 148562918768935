import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {SafeResourceUrl} from '@angular/platform-browser';
import {AuthorizationService} from '../../../services/authorization.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {

  @Input() index: number;

  /**
   * Array of URLs to images or YouTube videos
   */
  @Input() images: string[] | { title: string, link: string }[];
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private authorizationService: AuthorizationService) {
  }

  ngOnInit(): void {
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'Escape') {
      this.close.emit(null);
    }
  }

  nextImage() {
    this.index = (this.index + 1) % this.images.length;
  }

  prevImage() {
    this.index = this.index < 1 ? this.images.length - 1 : this.index - 1;
  }

  isVideo(url: string | { title: string, link: string }): boolean {
    let realUrl = (typeof url === 'string') ? url : url.link;
    return !!realUrl.includes('youtube.com') || !!realUrl.includes('youtu.be');
  }

  getVid(url: string | { title: string, link: string }): SafeResourceUrl {
    let realUrl = (typeof url === 'string') ? url : url.link;
    if (realUrl.includes('watch?v=')) {
      return this.authorizationService.sanatizeUrl((realUrl.replace("watch?v=", "embed/")));
    } else if (realUrl.includes('youtu.be')) {
      const id = realUrl.substring(realUrl.lastIndexOf('/') + 1);
      return this.authorizationService.sanatizeUrl(`https://www.youtube.com/embed/${id}`);
    } else {
      return this.authorizationService.sanatizeUrl((realUrl));
    }
  }

  getTitle(vid: string | { title: string, link: string }): string {
    return (typeof vid === 'string') ? null : vid.title;
  }
}
