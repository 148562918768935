import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-space-gallery-holder',
  templateUrl: './space-gallery-holder.component.html',
  styleUrls: ['./space-gallery-holder.component.scss']
})
export class SpaceGalleryHolderComponent {

  @Input() gallery: { title: string, cover: string, date: string, intro: string, medias: { type: string, media: string }[] };
  modalImages: string[];
  modalIndex: number;

  youtubeMedia = '../../../../assets/images/common/youtbe-media.png';

  constructor() { }

  openModal(gallery, index: number) {
    this.modalImages = gallery.map((item: any) => { return item.media });
    this.modalIndex = index;
  }

  closeModal() {
    this.modalIndex = null;
    this.modalImages = null;
  }

}
