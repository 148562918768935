import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-music-space-gallery',
  templateUrl: './music-space-gallery.component.html',
  styleUrls: ['./music-space-gallery.component.scss']
})
export class MusicSpaceGalleryComponent {

  space: { banner: string, intro: string, title: string };
  gallerys: { title: string, cover: string, date: string, intro: string, endDate: Date, medias: { type: string, media: string }[] }[];
  sortedGallerys: { title: string, cover: string, date: string, intro: string, medias: { type: string, media: string }[] }[];
  p: number = 1;

  constructor(
    public authService: AuthorizationService,
    private route: ActivatedRoute
  ) {
    route.params.subscribe((data: any) => {
      this.getSpace(data.id);
      this.getEvents(data.id);
    })
  }

  getSpace(input: number) {
    this.authService.getReq('https://cms.bncmusical.co.in/wp-json/wp/v2/music_space_taxonomies/' + input, true).subscribe((data: any) => {
      this.space = { banner: data.acf.events_page_banner, intro: data.acf.introduction, title: data.acf.title };
    })
  }

  getEvents(input: string) {
    this.authService.getReq('music_space_events?per_page=1000').subscribe((data: any[]) => {
      this.gallerys = data.map((galleryItem: any) => {
        if (galleryItem.acf.music_space === parseInt(input)) {
          console.log(typeof (galleryItem.acf.end_date));
          return {
            title: galleryItem.acf.title ? galleryItem.acf.title : '',
            cover: galleryItem.acf.cover_picture ? galleryItem.acf.cover_picture : false,
            date: galleryItem.acf.date ? galleryItem.acf.date : '',
            intro: galleryItem.acf.intro ? galleryItem.acf.intro : '',
            endDate: galleryItem.acf.end_date ? new Date(galleryItem.acf.end_date) : new Date('1-1-2000'),
            medias: galleryItem.acf.medias ? galleryItem.acf.medias.map((mediaItem: any) => {
              if (mediaItem.type === 'Image') {
                return {
                  type: 'Image',
                  media: mediaItem.image
                }
              } else if (mediaItem.type === 'Youtube') return {
                type: 'Youtube',
                media: mediaItem.youtube_link
              }
            }) : []
          }
        }
      }).filter(galleryItem => galleryItem).sort((a, b) => ((a.endDate > b.endDate) ? -1 : 1));
    });
  }

  handlePageChange() {
    window.scrollTo({ top: 500 });
  }

}
