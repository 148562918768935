import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlbumTop} from "../../../routes/album/models";
import {AuthorizationService} from "../../../services/authorization.service";

@Component({
  selector: 'app-album-cd-holder',
  templateUrl: './album-cd-holder.component.html',
  styleUrls: ['./album-cd-holder.component.scss']
})
export class AlbumCdHolderComponent implements OnInit {

  @Input() album: AlbumTop;
  @Input() slimWide: boolean;
  @Output() detailsclick = new EventEmitter<any>();

  constructor(public authService: AuthorizationService) { }

  ngOnInit(): void {
  }

}
