import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-workshop-gallery-holder',
  templateUrl: './workshop-gallery-holder.component.html',
  styleUrls: ['./workshop-gallery-holder.component.scss']
})
export class WorkshopGalleryHolderComponent {

  modalImages: string[];
  modalIndex: number;

  youtubeMedia = '../../../../assets/images/common/youtbe-media.png';
  @Input() workshop: { title: string, about: string, date: string, gurus: string, medias: { type: string, media: string }[] };

  constructor() { }

  openModal(gallery, index: number) {
    this.modalImages = gallery.map((item: any) => { return item.media });
    this.modalIndex = index;
  }

  closeModal() {
    this.modalIndex = null;
    this.modalImages = null;
  }

}
