import { Component } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-home-residency',
  templateUrl: './home-residency.component.html',
  styleUrls: ['./home-residency.component.scss']
})
export class HomeResidencyComponent {

  residencies: { id: string, name: string, date: string, content: string, picture: string }[];
  constructor(
    public authService: AuthorizationService
  ) {
    this.getResidencies();
  }

  getResidencies() {
    this.authService.getReq('residencies?per_page=300').subscribe((data: any[]) => {
      this.residencies = data.map((residency: any) => {
        if (residency.acf.featured_on_homepage) {
          return {
            id: residency.id,
            name: residency.acf.title,
            date: residency.acf.start_date + '-' + residency.acf.end_date,
            content: residency.acf.content,
            picture: residency.acf.cover_picture
          }
        }
      }).filter(residency => residency)
    })
  }


  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    // "nextArrow": "<div class='nav-btn next-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; right: -41px; cursor: pointer'></div>",
    // "prevArrow": "<div class='nav-btn prev-slide'><img src='../../../../assets/components/utilities/nav-icon.png' style='width: 60px; position: absolute; top: 48%; left: -41px; cursor: pointer; transform: rotate(180deg);'></div>",
    "dots": false,
    "infinite": false,
    "autoplay": false,
    "autoplaySpeed": 2000,
    responsive: [
      {
        breakpoint: 1920,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }, {
        breakpoint: 1024, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }, {
        breakpoint: 600, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }, {
        breakpoint: 480, settings: {
          slidesToShow: 1, slidesToScroll: 1
        }
      }
    ]
  };
  slickInit(e) {
  }
  breakpoint(e) {
  }
  afterChange(e) {
  }
  beforeChange(e) {
  }
}
