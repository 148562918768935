<div class="container p-1 main">

  <div class="row">

    <div class="col-lg-4 col-md-6 py-1 px-0">
      <div class="view overlay grid-item-span-row">
        <img class="col-md p-0" [src]="residency.cover">
        <div class="mask rgba-black-strong p-5">
          <h4 class="white-text mt-1">{{residency.title}}</h4>
          <p class="white-text mt-2">{{residency.content}}</p>
          <div class="zoom"></div>
        </div>
      </div>
    </div>

    <div class="container col-lg-8 col-md-12 p-0">

      <ul class="row p-0">
        <li class="col-lg-4 col-md-4 p-1 grid-item" (click)="startModal(0)">
          <img class="col-md p-0" [src]="residency.images[0]" *ngIf="residency.images[0]">
        </li>
        <li *ngIf="residency.video" class="col-lg-4 col-md-4 p-1 grid-item" (click)="startModal(1)">
          <a target="_blank">
            <img class="col-md p-0" src="{{getVidThumbnailUrl(residency.video)}}" alt="Video">
          </a>
          <img src="./assets/images/common/ic_youtube.webp" class="yt mb-auto" *ngIf="residency.video">
        </li>

        <li class="col-lg-4 col-md-4 p-1 grid-item" *ngFor="let image of residency.images | slice:1; let i = index"
            (click)="startModal(i + (residency.video ? 2 : 1))">
          <img class="col-md p-0" [src]="image" *ngIf="image">
        </li>
      </ul>

    </div>

  </div>
</div>
