import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';
import {ModalDirective, NavbarComponent as Navbar} from 'angular-bootstrap-md';
import {FormControl, FormGroup, Validators} from '@angular/forms';

const NAV_COLLAPSE_THRESHOLD = 160;
const NAV_EXPAND_THRESHOLD = 20;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  genres: { id: number, name: string }[];
  @ViewChild(Navbar) nav: Navbar;
  @ViewChild('logo') logo: ElementRef;
  validatingForm: FormGroup;
  @ViewChild('frame') frame: ModalDirective;

  constructor(
    public authService: AuthorizationService
  ) {
    this.getGenres();
    this.validatingForm = new FormGroup({
      contactFormModalName: new FormControl('', Validators.required),
      contactFormModalEmail: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      contactFormModalSubject: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', Validators.required)
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > NAV_COLLAPSE_THRESHOLD) {
      this.nav.renderer.addClass(this.nav.navbar.nativeElement, 'nav-collapse');
      this.logo.nativeElement.classList.add('nav-collapse');
    } else if (window.pageYOffset < NAV_EXPAND_THRESHOLD) {
      this.nav.renderer.removeClass(this.nav.navbar.nativeElement, 'nav-collapse');
      this.logo.nativeElement.classList.remove('nav-collapse');
    }
  }

  getGenres() {
    this.authService.getReq('genres?per_page=20').subscribe((data: any[]) => {
      this.genres = data.map((genre: any) => {
        return {
          id: genre.id,
          name: genre.acf.name
        };
      });
    });
  }

  get contactFormModalName() {
    return this.validatingForm.get('contactFormModalName');
  }

  get contactFormModalEmail() {
    return this.validatingForm.get('contactFormModalEmail');
  }

  get contactFormModalSubject() {
    return this.validatingForm.get('contactFormModalSubject');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  send() {
    if (this.validatingForm.status === 'VALID') {
      const body = {
        senderTitle: 'BNC',
        email: 'musical@banglanatak.com',
        emailTitle: 'Enquiry from Banglanatk Dot Com',
        emailBody: `<h3>${this.validatingForm.value.contactFormModalName} just made an enquiry<h3>`
          + `<p>Name: ${this.validatingForm.value.contactFormModalName}</p><p>Email: `
          + `${this.validatingForm.value.contactFormModalEmail}</p><p> Subject: ${this.validatingForm.value.contactFormModalSubject}</p>`
          + `<p> Message: ${this.validatingForm.value.contactFormModalMessage}</p>`
      };
      this.authService.sendMail(body).subscribe(
        (data: any) => {
          // console.log('data', data, 'body', body);
        }
      );
    }

  }

  public showForm() {
    this.frame.show();
  }

}
