<div class="container">
  <h2>Sur Jahan</h2>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-5 p-0">
      <div class="surjahan-image d-none d-md-block mr-2"></div>
      <img src="../../../../assets/images/home/Surjahan.jpg" class="d-block d-md-none w-100 mb-2" alt="">
    </div>
    <div class="col-md-7 p-0">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/_rstEVrSM6M"></iframe>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <br>
  <p class="content-width text-center m-auto">Sur Jahan (previous name Sufi Sutra) is an annual three-day World Peace
    Music
    Festival with the motto 'Music for Peace,
    Music for All'. Held in Kolkata on the first weekend of February followed by Goa, artists from around the world
    and
    different states of India participate in this non-commercial, non-ticketed festival. The format includes 1 daytime
    workshop and 1 evening concert for each of the participating international bands. Usually 5-6 International bands
    and 3 National
    bands participate in each edition.
  </p>
  <br>

  <h4 style="width: fit-content;" class="m-auto bg-orange px-4 py-2"><a style="color: black;" href="http://surjahan.com"
      target="_blank">www.surjahan.com</a></h4>
  <br>
</div>
