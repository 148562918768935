import {Component} from '@angular/core';
import {AuthorizationService} from 'src/app/services/authorization.service';
import {SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-documentaries',
  templateUrl: './documentaries.component.html',
  styleUrls: ['./documentaries.component.scss']
})
export class DocumentariesComponent {

  documentaries: { id: number, title: string, videos: { title: string, link: string }[] }[];
  modalVids: { title: string, link: string }[];
  currentPage = 1;

  constructor(
    private authService: AuthorizationService
  ) {
    this.getDocumentaries();
  }

  getDocumentaries() {
    this.authService.getReq('documentaries?per_page=100').subscribe((data: any[]) => {
      this.documentaries = data.map((documentary: any) => {
        return {
          id: documentary.id,
          title: documentary.acf.title,
          videos: documentary.acf.videos.map((video: any) => {
            return {
              title: video.title,
              link: video.youtube_link
            };
          })
        };
      });
    });
  }

  /**
   * Returns a thumbnail URL for the given video
   * @param vidUrl Expected format: "https://www.youtube.com/embed/<id>"
   */
  getVidThumbnailUrl(vidUrl: string): string {
    const id = vidUrl.substr(vidUrl.lastIndexOf('/'));
    return `https://img.youtube.com/vi${id}/mqdefault.jpg`;
  }

  showModal(vids: { title: string, link: string }[]): void {
    this.modalVids = vids;
  }

  hideModal() {
    this.modalVids = null;
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

}
