<div class="container">
  <div class="row">
    <!-- <div class="col-md-5 embed-responsive embed-responsive-1by1 holder-img"> -->
    <!-- <div class="image-holder" [style.background-image]="'url(' + event.images[imageIndex] +')'"
        (click)="isModalActive = true"></div> -->
    <!-- <img class="col p-0 embed-responsive-item" [src]="event.images[imageIndex]" (click)="isModalActive = true">
      <mdb-icon fas size="2x" icon="caret-right amber-text" class="img-next" (click)="nextImage()"></mdb-icon>
      <mdb-icon fas size="2x" icon="caret-left amber-text" class="img-prev" (click)="prevImage()"></mdb-icon> -->
    <!-- </div> -->
    <div class="col-md-5 bg-dark-grey p-3">
      <div class="image-holder" [style.background-image]="'url(' + event.images[imageIndex] +')'"
        (click)="isModalActive = true"></div>
      <mdb-icon fas size="2x" icon="caret-right amber-text" class="img-next" (click)="nextImage()"></mdb-icon>
      <mdb-icon fas size="2x" icon="caret-left amber-text" class="img-prev" (click)="prevImage()"></mdb-icon>
    </div>
    <div class="col-md-7 p-5 bg-grey holder-text">
      <h2>{{event.title}}</h2>
      <p class="date">Date:</p>
      <p class="content">{{event.date}}</p>
      <div class="scrollbar scrollbar-primary">
        <p class="content body mt-3 force-overflow">{{event.content}}</p>
      </div>
    </div>
  </div>
</div>

<app-image-modal *ngIf="isModalActive" [images]="event.images" [index]="imageIndex" (close)="isModalActive = false">
</app-image-modal>