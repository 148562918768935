<app-styled-banner [banner]='{image: "../../../../assets/images/workshops/workshop.jpg", title: "Workshops", color: "#e64675"}'>
</app-styled-banner>
<div class="container my-4">
  <div class="content-width m-auto">
    <p>We organize workshops for art forms with oral tradition, where transmission of heritage skills is done through
      Guru
      Shishya Parampara. It is very popular amongst traditional folk practitioners. We also encourage and impart
      training on
      instrumentation, recording, sound engineering etc. and invite national and international exponents for the same.
    </p>
  </div>
</div>

<div *ngFor='let workshop of workshops'>
  <app-workshop-gallery-holder [workshop]='workshop'></app-workshop-gallery-holder>
</div>