<app-home-upper></app-home-upper>
<app-upcoming-events></app-upcoming-events>
<app-grey-seperator></app-grey-seperator>
<app-home-sur-jahan></app-home-sur-jahan>
<app-grey-seperator></app-grey-seperator>
<app-home-residency></app-home-residency>
<app-grey-seperator></app-grey-seperator>
<app-home-genre></app-home-genre>
<!-- <app-grey-seperator></app-grey-seperator> -->
<!-- <app-home-folks-of-bengal></app-home-folks-of-bengal> -->
<app-grey-seperator></app-grey-seperator>
<app-home-safar-tunes></app-home-safar-tunes>
<app-grey-seperator></app-grey-seperator>
<app-home-event-social-media></app-home-event-social-media>
