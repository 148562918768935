import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-home-safar-tunes',
  templateUrl: './home-safar-tunes.component.html',
  styleUrls: ['../home-folks-of-bengal/home-folks-of-bengal.component.scss', './home-safar-tunes.component.scss']
})
export class HomeSafarTunesComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
